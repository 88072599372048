import { APP_INITIALIZER, Provider } from '@angular/core'
import { Observable } from 'rxjs'
import { MetadataService } from './metadata.service'

export function loadInitialData(metadata: MetadataService): () => Observable<void> {
    return () => metadata.loadData()
}

export function provideMetadataLoading(): Array<Provider> {
    return [
        MetadataService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadInitialData,
            deps: [MetadataService],
            multi: true
        }
    ]
}
