import { inject, Injectable } from '@angular/core'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    expectAsyncResultHttpError
} from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import { Payment } from './payment'
import { PaymentService } from './payment.service'

@Injectable({
    providedIn: 'root'
})
export class PaymentFacade {
    private paymentService: PaymentService = inject(PaymentService)

    public createPayment(bookingId: number): Observable<AsyncResult<Payment | null>> {
        return this.paymentService.createPayment(bookingId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }
}
