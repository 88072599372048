import { Injectable } from '@angular/core'
import { EMPTY_PAGED_LIST_INITIAL, PagedList } from '@linkx/shared-utils'
import { Action, State, StateContext } from '@ngxs/store'
import { EmployeeListFilter } from '../employee-list/employee-list-filter.model'
import { EmployeeSearchFilter } from '../employee-search/employee-search-filter.model'
import { EmployeeSearch, employeeSearchFromDto } from '../employee-search/employee-search.model'
import {
    BackofficeEmployeesLoaded,
    ClearEmployeeSearch,
    EmployeeSearchInserted,
    EmployeeSearchLoaded,
    EmployeeUpdated
} from './employee.action'

export interface EmployeeStateModel {
    employeesSearchList: PagedList<EmployeeSearch, EmployeeSearchFilter>
    backofficeEmployees: PagedList<EmployeeSearch, EmployeeListFilter>
}

export const EMPLOYEE_STATE_DEFAULTS: EmployeeStateModel = {
    employeesSearchList: EMPTY_PAGED_LIST_INITIAL as PagedList<EmployeeSearch, EmployeeSearchFilter>,
    backofficeEmployees: EMPTY_PAGED_LIST_INITIAL as PagedList<EmployeeSearch, EmployeeListFilter>
}

@State<EmployeeStateModel>({
    name: 'employees',
    defaults: EMPLOYEE_STATE_DEFAULTS
})
@Injectable()
export class EmployeesState {
    @Action(BackofficeEmployeesLoaded)
    public backofficeEmployeesLoaded(ctx: StateContext<EmployeeStateModel>, action: BackofficeEmployeesLoaded): void {
        ctx.patchState({ backofficeEmployees: action.employees })
    }

    @Action(EmployeeSearchLoaded)
    public employeesSearchLoaded(ctx: StateContext<EmployeeStateModel>, action: EmployeeSearchLoaded): void {
        ctx.setState({ ...ctx.getState(), employeesSearchList: action.employeesSearch })
    }

    @Action(EmployeeSearchInserted)
    public employeeInserted(ctx: StateContext<EmployeeStateModel>, action: EmployeeSearchInserted): void {
        const state: EmployeeStateModel = ctx.getState()
        ctx.setState({
            ...ctx.getState(),
            employeesSearchList: {
                status: 'success',
                filters: null,
                entity: {
                    ...state.employeesSearchList.entity,
                    items: [
                        ...state.employeesSearchList.entity.items,
                        employeeSearchFromDto({
                            id: action.employee.id ? action.employee.id : 0,
                            city: action.employee.address.city,
                            firstName: action.employee.firstName,
                            languages: [],
                            lastName: action.employee.lastName,
                            workExperiences: []
                        })
                    ]
                }
            }
        })
    }

    @Action(EmployeeUpdated)
    public employeeUpdated(ctx: StateContext<EmployeeStateModel>, action: EmployeeUpdated): void {
        const state: EmployeeStateModel = ctx.getState()
        ctx.setState({
            ...state,
            employeesSearchList: {
                ...state.employeesSearchList,
                entity: {
                    ...state.employeesSearchList.entity,
                    items: state.employeesSearchList.entity.items.map((employee) => {
                        if (employee.id === action.employee.id) {
                            return employeeSearchFromDto({
                                id: action.employee.id,
                                city: action.employee.address.city,
                                firstName: action.employee.firstName,
                                languages: [],
                                lastName: action.employee.lastName,
                                workExperiences: []
                            })
                        }
                        return employeeSearchFromDto({
                            id: employee.id,
                            city: employee.city,
                            firstName: employee.firstName,
                            languages: employee.languages,
                            lastName: employee.lastName,
                            workExperiences: employee.workExperiences
                        })
                    })
                }
            }
        })
    }
    @Action(ClearEmployeeSearch)
    public clearEmployeesSearchList(ctx: StateContext<EmployeeStateModel>): void {
        ctx.setState({
            ...ctx.getState(),
            employeesSearchList: {
                status: 'pending',
                filters: null,
                entity: {
                    items: [],
                    totalPages: 0,
                    totalCount: 0,
                    hasPreviousPage: false,
                    pageIndex: 1,
                    pageSize: 20,
                    hasNextPage: false
                }
            }
        })
    }
}
