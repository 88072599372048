<div class="toolbar">
    <button class="toolbar-button" *ngIf="showMenuToggle" (click)="toggleSidebar.emit()">
        <mat-icon *ngIf="!isSidenavOpen; else closeIcon">menu</mat-icon>
        <ng-template #closeIcon>
            <mat-icon>close</mat-icon>
        </ng-template>
    </button>
    <span class="spacer"></span>
    <button *ngIf="currentUser$ | async; else login" class="toolbar-button" (click)="auth.signOut()">
        <span>{{ 'auth.sign-out' | translate }}</span>
        <mat-icon>logout</mat-icon>
    </button>
</div>
<ng-template #login>
    <div>
        <button class="toolbar-button" (click)="auth.signInFacebook()">
            <i class="fa-brands fa-facebook"></i>
            <span>{{ 'auth.sign-in-facebook' | translate }}</span>
        </button>
        <button class="toolbar-button" (click)="auth.signInGoogle()">
            <i class="fa-brands fa-google"></i>
            <span>{{ 'auth.sign-in-google' | translate }}</span>
        </button>
    </div>
</ng-template>
