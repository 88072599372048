import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ProgressCircleType } from './progress-circle-type'

@Component({
    selector: 'linkx-progress-circle',
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule],
    templateUrl: './progress-circle.component.html',
    styleUrls: ['./progress-circle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressCircleComponent implements OnChanges {
    @Input() public max = 100
    @Input() public value = 0
    @Input() public type: ProgressCircleType = ProgressCircleType.normal

    private _progress!: number

    public get progress(): number {
        return this._progress
    }

    public ngOnChanges(): void {
        this._progress = (this.value / this.max) * 100
    }
}
