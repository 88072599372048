<h3 mat-dialog-title [translate]="data.titleKey" [translateParams]="data.titleParams"></h3>
<mat-dialog-content
    class="mat-typography"
    [translate]="data.bodyKey"
    [translateParams]="data.bodyParams"></mat-dialog-content>
<mat-dialog-actions align="end">
    <linkx-secondary-button
        [mat-dialog-close]="false"
        fontIcon="fa-ban"
        [label]="data.cancelKey | translate"></linkx-secondary-button>
    <linkx-primary-button
        [mat-dialog-close]="true"
        fontIcon="fa-check"
        [label]="data.confirmationKey | translate"></linkx-primary-button>
</mat-dialog-actions>
