import { Address, AddressDto, addressFromDto, addressToDto } from '../address/address.model'

export interface Company {
    vatNumber: string
    companyName: string
    address: Address
}

export interface CompanyDto {
    vatNumber: string
    companyName: string
    address: AddressDto
}

export const companyToDto = (company: Company): CompanyDto => {
    return {
        ...company,
        address: addressToDto(company.address)
    }
}

export const companyFromDto = (companyDto: CompanyDto): Company => {
    return {
        ...companyDto,
        address: addressFromDto(companyDto.address)
    }
}
