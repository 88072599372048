<div *ngIf="status" [ngSwitch]="status" class="icon-and-text">
    <i class="list-icon fa-solid fa-credit-card" *ngSwitchCase="'MONEY_DEPOSIT'"></i>
    <i class="list-icon fa-solid fa-coins" *ngSwitchCase="'BOOKING_FINANCED'"></i>
    <i class="list-icon fa-regular fa-circle-check" *ngSwitchCase="'SERVICES_COMPLETED'"></i>
    <i class="list-icon fa-solid fa-check-double" *ngSwitchCase="'SERVICES_CORRECTED'"></i>
    <i class="list-icon fa-solid fa-rotate-left" *ngSwitchCase="'UNDO_SERVICES_COMPLETED'"></i>
    <i class="list-icon fa-solid fa-pause" *ngSwitchCase="'BOOKING_INTERRUPTED'"></i>
    <i class="list-icon fa-solid fa-ban" *ngSwitchCase="'BOOKING_CANCELED'"></i>
    <i class="list-icon fa-solid fa-money-bills" *ngSwitchCase="'MONEY_WITHDRAWAL'"></i>
    <i class="list-icon fa-solid fa-money-bill-transfer" *ngSwitchCase="'MONEY_CORRECTION'"></i>
    <div *ngIf="showStatusText">{{ translationText | translate }}</div>
</div>
