import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { CognitoUserInfo, LinkxAuthFacade, LinkxUserInfo } from '@linkx/shared-auth'
import { Observable } from 'rxjs'
import { NavigationItem } from '../navigation-item/navigation-item.model'

@Component({
    selector: 'linkx-left-sidenav',
    templateUrl: './left-sidenav.component.html',
    styleUrls: ['left-sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftSidenavComponent {
    @Input() public navigationItems: Array<NavigationItem> | null = []
    @Input() public showMenuCloseButton = true
    @Output() public closeSidebar: EventEmitter<void> = new EventEmitter()

    private auth: LinkxAuthFacade = inject(LinkxAuthFacade)

    public currentUser$: Observable<CognitoUserInfo | null> = this.auth.getCurrentUser()

    public currentUserProfile$: Observable<LinkxUserInfo | null> = this.auth.loadUserProfile()
}
