import { Constructor } from './constructor'

/**
 * Interface describing something that supports checking whether it can be deactivated.
 */
export interface CanDeactivatePage {
    canDeactivate(): boolean
}

/** A constructable type that implements the CanPage interface. */
export type CanDeactivatePageCtor = Constructor<CanDeactivatePage>

/**
 * Enhances the given base class with functionality for pagination based on query parameters.
 * @param base The base class that already implements functionality for relative navigation.
 */

export const mixinCanDeactivate = <T extends Constructor<Record<string, unknown>>>(
    base: T
): T & CanDeactivatePageCtor => {
    return class extends base implements CanDeactivatePage {
        public canDeactivate(): boolean {
            return true
        }
    }
}
