import { translations } from '@aws-amplify/ui-angular'
import { AwsCognitoAppConfig } from '@linkx/shared-utils'
import { Amplify, I18n } from 'aws-amplify'

export const configureAmplify = (awsCognitoConfig: AwsCognitoAppConfig): void => {
    const uiUrl = `${location.origin}${location.pathname}`.replace(/\/$/, '')

    Amplify.configure({
        Auth: {
            identityPoolId: '',
            region: awsCognitoConfig.region,
            userPoolId: awsCognitoConfig.userPoolId,
            userPoolWebClientId: awsCognitoConfig.userPoolWebClientId,
            mandatorySignIn: true,
            oauth: {
                domain: awsCognitoConfig.domain,
                scope: ['phone', 'email', 'profile', 'aws.cognito.signin.user.admin', 'openid'],
                redirectSignIn: uiUrl,
                redirectSignOut: uiUrl,
                responseType: 'code',
                options: {
                    AdvancedSecurityDataCollectionFlag: false
                }
            }
        }
    })
}

export const configureAmplifyTranslations = (language: string): void => {
    I18n.putVocabularies(translations)
    I18n.setLanguage(language)
}
