import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { APP_CONFIG, AppConfig } from '@linkx/shared-utils'
import { CognitoAccessToken } from 'amazon-cognito-identity-js'
import { Observable, switchMap } from 'rxjs'
import { LinkxAuthFacade } from './linkx-auth.facade'

export const noAuthLinks = ['/metadata/', 'shared-assets', '/frontoffice/employees/search']

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private appConfig: AppConfig = inject(APP_CONFIG)
    private linkxAuth: LinkxAuthFacade = inject(LinkxAuthFacade)

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        //  Token will be not be added on certain metadata and has to be api call
        if (req.url.indexOf(this.appConfig.api.url) !== 0 || noAuthLinks.some((element) => req.url.includes(element))) {
            return next.handle(req)
        }

        const continueWithJwtToken = (jwtToken: string): Observable<HttpEvent<unknown>> => {
            return next.handle(
                req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
            )
        }

        if (this.linkxAuth.routeToken) {
            return continueWithJwtToken(this.linkxAuth.routeToken)
        }

        return this.linkxAuth
            .getActiveAccessToken()
            .pipe(switchMap((token: CognitoAccessToken) => continueWithJwtToken(token.getJwtToken())))
    }
}
