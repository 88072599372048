import { PagedList } from '@linkx/shared-utils'
import { Selector } from '@ngxs/store'
import { EmployeeListFilter } from '../employee-list/employee-list-filter.model'
import { EmployeeSearchFilter } from '../employee-search/employee-search-filter.model'
import { EmployeeSearch } from '../employee-search/employee-search.model'
import { EmployeesState, EmployeeStateModel } from './employee.state'

export class EmployeeSelector {
    @Selector([EmployeesState])
    public static backofficeEmployees(state: EmployeeStateModel): PagedList<EmployeeSearch, EmployeeListFilter> {
        return state.backofficeEmployees
    }

    @Selector([EmployeesState])
    public static employeeSearchList(state: EmployeeStateModel): PagedList<EmployeeSearch, EmployeeSearchFilter> {
        return state.employeesSearchList
    }
}
