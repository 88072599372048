import { notNull } from '@ppwcode/js-ts-oddsandends/lib/conditional-assert'
import { Qualification } from '../../qualification/qualification.model'

export interface EmployeeSearchFilter {
    qualification: Qualification | null
}

export interface EmployeeSearchFilterDto {
    qualificationId: number
}

export const employeeSearchFilterToDto = (model: EmployeeSearchFilter): EmployeeSearchFilterDto => {
    return {
        qualificationId: notNull(model.qualification).id
    }
}
