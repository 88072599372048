import { inject, Injectable } from '@angular/core'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    EMPTY_PAGED_LIST_INITIAL,
    expectAsyncResultHttpError,
    expectPagedListHttpError,
    expectPagedListHttpSuccess,
    PagedList
} from '@linkx/shared-utils'
import { Select, Store } from '@ngxs/store'
import { map, Observable, of, switchMap } from 'rxjs'
import { EmployeeSearchLoaded } from '../+state/employee.action'
import { EmployeeSelector } from '../+state/employee.selector'
import { EmployeeSearchDetail } from '../employee-search/employee-search-detail.model'
import { EmployeeSearchFilter } from '../employee-search/employee-search-filter.model'
import { EmployeeSearch } from '../employee-search/employee-search.model'
import { FrontOfficeEmployeeService } from '../services/frontoffice-employee.service'

@Injectable({ providedIn: 'root' })
export class FrontOfficeEmployeeFacade {
    @Select(EmployeeSelector.employeeSearchList)
    private readonly employeeSearchList$!: Observable<PagedList<EmployeeSearch, EmployeeSearchFilter>>

    private frontOfficeEmployeeService: FrontOfficeEmployeeService = inject(FrontOfficeEmployeeService)
    private store: Store = inject(Store)

    public getEmployeesByCriteria(
        searchFilter: EmployeeSearchFilter,
        page: number,
        pageSize: number
    ): Observable<PagedList<EmployeeSearch, EmployeeSearchFilter>> {
        const emptyPagedList = EMPTY_PAGED_LIST_INITIAL as PagedList<EmployeeSearch, EmployeeSearchFilter>
        const dispatchLoaded = (pl: PagedList<EmployeeSearch, EmployeeSearchFilter>): Observable<unknown> =>
            this.store.dispatch(new EmployeeSearchLoaded(pl))

        if (searchFilter.qualification && searchFilter.qualification.id > 0) {
            return this.frontOfficeEmployeeService.getAllByCriteria(searchFilter, page, pageSize).pipe(
                expectPagedListHttpSuccess(dispatchLoaded, searchFilter),
                expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded, searchFilter),
                switchMap(() => this.employeeSearchList$)
            )
        } else {
            return of(emptyPagedList)
        }
    }

    public getSearchEmployeeById(id: number): Observable<AsyncResult<EmployeeSearchDetail | null>> {
        return this.frontOfficeEmployeeService.getSearchDetailById(id).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }
}
