import { AsyncResult, createFailedAsyncResult, createSuccessAsyncResult } from './async-result'
import { PagedEntities } from './paged-entities'

export type PagedList<TEntity, TFilter = null> = AsyncResult<PagedEntities<TEntity>, TFilter>

export const createSuccessPagedListResult = <TEntity, TFilter = null>(
    entities: PagedEntities<TEntity>,
    filters?: TFilter
): PagedList<TEntity, TFilter> => {
    return createSuccessAsyncResult<PagedEntities<TEntity>, TFilter>(entities, filters)
}

export const createFailedPagedListResult = <TEntity, TFilter = null>(
    error: Error,
    entities: PagedEntities<TEntity>,
    filters?: TFilter
): PagedList<TEntity, TFilter> => {
    return createFailedAsyncResult<PagedEntities<TEntity>, TFilter>(error, entities, filters)
}

export const isPagedListSameQuery = <TEntity, TFilter = null>(
    pagedList: PagedList<TEntity, TFilter>,
    pageIndex: number,
    searchCriteria: TFilter | null = null
): boolean => {
    return (
        pagedList.entity.pageIndex == pageIndex &&
        pagedList.entity.totalCount !== 0 &&
        pagedList.status === 'success' &&
        JSON.stringify(pagedList.filters) === JSON.stringify(searchCriteria)
    )
}
