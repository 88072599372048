import { BehaviorSubject, MonoTypeOperatorFunction, Observable, of, switchMap, tap } from 'rxjs'
import { Constructor } from './constructor'

/**
 * Interface describing something that supports "pending" tracking.
 */
export interface CanTrackPending {
    /** Tracks the pending state. */
    pending$: BehaviorSubject<boolean>

    /* eslint-disable @typescript-eslint/no-explicit-any */
    startPending: () => MonoTypeOperatorFunction<any>
    stopPending: () => MonoTypeOperatorFunction<any>
    /* eslint-enable @typescript-eslint/no-explicit-any */

    /** Wraps the given observable with the startPending and stopPending tracking functions. */
    trackPending<T>(stream$: Observable<T>): Observable<T>
}

/** A constructable type that implements the CanPage interface. */
export type CanTrackPendingCtor = Constructor<CanTrackPending>

/**
 * Enhances the given base class with functionality for tracking a "pending" state.
 * The initial state of the pending situation is "true".
 * @param isInitiallyPending Whether the pending state is true on initial creation of the class.
 * @param base An optional base class.
 */
export const mixinTrackPending = <T extends Constructor<Record<string, unknown>>>(
    isInitiallyPending: boolean = true,
    base?: T
): T & CanTrackPendingCtor => {
    base ??= class {} as T
    return class extends base implements CanTrackPending {
        public pending$: BehaviorSubject<boolean> = new BehaviorSubject(isInitiallyPending)

        /* eslint-disable @typescript-eslint/no-explicit-any */
        public startPending = (): MonoTypeOperatorFunction<any> => tap(() => this.pending$.next(true))
        public stopPending = (): MonoTypeOperatorFunction<any> => tap(() => this.pending$.next(false))
        /* eslint-enable @typescript-eslint/no-explicit-any */

        public trackPending<T>(stream$: Observable<T>): Observable<T> {
            return of(null).pipe(
                this.startPending(),
                switchMap(() => stream$),
                this.stopPending()
            )
        }
    }
}
