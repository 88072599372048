import { HttpClient, HttpStatusCode } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { APP_CONFIG, AppConfig, expectHttpError } from '@linkx/shared-utils'
import { CognitoAccessToken, CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { ICredentials } from 'aws-amplify/lib/Common/types/types'
import { from, map, Observable, Observer, of, switchMap, withLatestFrom } from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { CognitoUserInfo } from './cognito-user-info'
import { CreateOrUpdateUserEmployer, createOrUpdateUserEmployerToDto } from './create-or-update-user-employer'
import { LinkxUserInfo, LinkxUserInfoDto, linkxUserInfoFromDto } from './linkx-user-info'

@Injectable({
    providedIn: 'root'
})
export class LinkxAuthService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)

    public async currentUser(): Promise<CognitoUserInfo | null> {
        try {
            const poolUser = await Auth.currentUserPoolUser()
            const { email, email_verified, family_name, identities, given_name, sub } = poolUser.attributes
            const parsedIdentities: Array<{ providerType: string }> = JSON.parse(identities)
            return {
                email,
                emailVerified: email_verified,
                familyName: family_name,
                identityProvider: parsedIdentities[0]?.providerType ?? '',
                givenName: given_name,
                sub: sub,
                userSessionPayload: poolUser.signInUserSession.accessToken.payload
            }
        } catch {
            // Not authenticated.
            return null
        }
    }

    public signInFacebook(): Promise<ICredentials> {
        return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
    }

    public signInGoogle(): Promise<ICredentials> {
        return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }

    public signOut(): Promise<void> {
        return Auth.signOut()
    }

    public getCurrentSession(): Promise<CognitoUserSession> {
        return Auth.currentSession()
    }

    public refreshSession(): Observable<CognitoUserSession> {
        const createRefreshSessionStream = (refreshToken: CognitoRefreshToken) => (currentUser: CognitoUser) => {
            return new Observable((observer: Observer<CognitoUserSession>) => {
                currentUser.refreshSession(refreshToken, (error, newSession) => {
                    if (error) {
                        // When an error occurred, we are in an invalid authentication state, and we should log out the user!
                        this.signOut()
                        observer.error(error)
                        observer.complete()
                    } else {
                        observer.next(newSession)
                        observer.complete()
                    }
                })
            })
        }

        return from(this.getCurrentSession()).pipe(
            map((currentSession: CognitoUserSession) => currentSession.getRefreshToken()),
            switchMap((refreshToken: CognitoRefreshToken) =>
                from(Auth.currentAuthenticatedUser()).pipe(switchMap(createRefreshSessionStream(refreshToken)))
            )
        )
    }

    public refreshAccessToken(): Observable<CognitoAccessToken> {
        return this.refreshSession().pipe(map((session: CognitoUserSession) => session.getAccessToken()))
    }

    public getAccessToken(): Observable<CognitoAccessToken> {
        return from(this.getCurrentSession()).pipe(
            switchMap((currentSession: CognitoUserSession) => {
                const accessTokenExpiration = currentSession.getAccessToken().getExpiration()
                const currentTimeSeconds = Math.round(+new Date() / 1000)

                if (accessTokenExpiration < currentTimeSeconds) {
                    return this.refreshAccessToken()
                }

                return of(currentSession.getAccessToken())
            })
        )
    }

    public getRefreshToken(): Observable<CognitoRefreshToken> {
        return from(this.getCurrentSession()).pipe(map((currentSession) => currentSession.getRefreshToken()))
    }

    public getUser(sub: string): Observable<LinkxUserInfo | null> {
        const { url, version } = this.appConfig.api
        return this.http.get<LinkxUserInfoDto>(`${url}/${version}/User/${sub}`).pipe(
            withLatestFrom(fromPromise(this.currentUser())),
            map(([dto, cognito]) => linkxUserInfoFromDto(dto, cognito)),
            expectHttpError([HttpStatusCode.NotFound], () => null)
        )
    }

    public saveUserEmployer(createOrUpdateUserEmployer: CreateOrUpdateUserEmployer): Observable<LinkxUserInfo> {
        const { url, version } = this.appConfig.api
        return this.http
            .put<LinkxUserInfoDto>(
                `${url}/${version}/User/Employer`,
                createOrUpdateUserEmployerToDto(createOrUpdateUserEmployer)
            )
            .pipe(
                withLatestFrom(fromPromise(this.currentUser())),
                map(([dto, cognito]) => linkxUserInfoFromDto(dto, cognito))
            )
    }
}
