import { Injectable } from '@angular/core'
import { createEmptyPagedEntities, PagedList } from '@linkx/shared-utils'
import { Action, State, StateContext, StateToken } from '@ngxs/store'
import { Employer } from '../employer.model'
import {
    ClearEmployers,
    EmployerInserted,
    EmployersLoaded,
    EmployerTransactionlogsLoaded,
    EmployerUpdated
} from './employer.action'
import { EmployerListFilter } from '../employer-list-filter/employer-list-filter.model'
import { Transactionlog } from '../../transactionlog/transactionlog.model'
import { MyTransactionlogsListFilter } from '../../transactionlog/my-transactionlogs-filter/my-transactionlogs-list-filter.model'

export interface EmployerStateModel {
    employers: PagedList<Employer, EmployerListFilter>
    transactionlogs: PagedList<Transactionlog, MyTransactionlogsListFilter>
}

export const EMPLOYER_STATE_DEFAULTS: EmployerStateModel = {
    employers: {
        entity: {
            items: [],
            hasNextPage: false,
            pageSize: 20,
            pageIndex: 1,
            hasPreviousPage: false,
            totalCount: 0,
            totalPages: 1
        },
        status: 'pending',
        filters: null
    },
    transactionlogs: {
        entity: createEmptyPagedEntities(),
        status: 'pending',
        filters: null
    }
}

export const EMPLOYER_STATE_TOKEN = new StateToken<EmployerStateModel>('employers')

@State<EmployerStateModel>({
    name: EMPLOYER_STATE_TOKEN,
    defaults: EMPLOYER_STATE_DEFAULTS
})
@Injectable()
export class EmployersState {
    @Action(EmployersLoaded)
    public employersLoaded(ctx: StateContext<EmployerStateModel>, action: EmployersLoaded): void {
        ctx.setState({ ...ctx.getState(), employers: action.employers })
    }

    @Action(EmployerInserted)
    public employerInserted(ctx: StateContext<EmployerStateModel>, action: EmployerInserted): void {
        const state: EmployerStateModel = ctx.getState()
        ctx.setState({
            transactionlogs: {
                entity: createEmptyPagedEntities(),
                status: 'pending',
                filters: null
            },
            employers: {
                status: 'success',
                filters: null,
                entity: {
                    ...state.employers.entity,
                    items: [...state.employers.entity.items, action.employer]
                }
            }
        })
    }

    @Action(EmployerTransactionlogsLoaded)
    public employerTransactionlogsLoaded(
        ctx: StateContext<EmployerStateModel>,
        action: EmployerTransactionlogsLoaded
    ): void {
        ctx.setState({ ...ctx.getState(), transactionlogs: action.transactionlogs })
    }

    @Action(EmployerUpdated)
    public employerUpdated(ctx: StateContext<EmployerStateModel>, action: EmployerUpdated): void {
        const state: EmployerStateModel = ctx.getState()
        ctx.setState({
            ...state,
            employers: {
                ...state.employers,
                entity: {
                    ...state.employers.entity,
                    items: state.employers.entity.items.map((employer) =>
                        employer.id === action.employer.id ? action.employer : employer
                    )
                }
            }
        })
    }

    @Action(ClearEmployers)
    public clearEmployers(ctx: StateContext<EmployerStateModel>): void {
        ctx.setState({
            ...ctx.getState(),
            employers: {
                status: 'pending',
                filters: null,
                entity: {
                    items: [],
                    totalPages: 0,
                    totalCount: 0,
                    hasPreviousPage: false,
                    pageIndex: 1,
                    pageSize: 20,
                    hasNextPage: false
                }
            }
        })
    }
}
