import { DateTime } from 'luxon'
import { TransactionlogStatus } from './transactionlog-status.enum'
export interface Transactionlog {
    id: number | null
    description: string
    deltaAmount: number
    balance: number
    deltaReservedAmount: number
    reservedBalance: number
    status: TransactionlogStatus
    transactionLogDate: DateTime
    bookingId: number | null
    employerId: number
}

export interface TransactionlogDto {
    id: number | null
    description: string
    deltaAmount: number
    balance: number
    deltaReservedAmount: number
    reservedBalance: number
    status: TransactionlogStatus
    transactionLogDate: string
    bookingId: number | null
    employerId: number
}

export const transactionLogFromDto = (transactionlogDto: TransactionlogDto): Transactionlog => {
    return {
        ...transactionlogDto,
        transactionLogDate: DateTime.fromISO(transactionlogDto.transactionLogDate)
    }
}
