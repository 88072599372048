import { Qualification } from '../../qualification/qualification.model'

export interface EmployeeListFilter {
    firstName: string
    lastName: string
    companyName: string
    companyCity: string
    vatNumber: string
    email: string
    qualification: Qualification | null
}

export interface EmployeeListFilterDto extends Record<string, unknown> {
    firstName: string
    lastName: string
    companyName: string
    companyCity: string
    vatNumber: string
    email: string
    qualificationId: number | null
}

export const employeeListFilterToDto = (model: EmployeeListFilter): EmployeeListFilterDto => {
    return {
        firstName: model.firstName,
        lastName: model.lastName,
        companyName: model.companyName,
        companyCity: model.companyCity,
        vatNumber: model.vatNumber,
        email: model.email,
        qualificationId: model.qualification?.id ?? null
    }
}
