import {
    WorkExperience,
    WorkExperienceDto,
    workExperienceFromDto,
    workExperienceToDto
} from '../../workexperience/workexperience.model'

export interface EmployeeUpdateWorkExperience {
    workExperiences: Array<WorkExperience>
}

export interface EmployeeUpdateWorkExperienceDto {
    workExperiences: Array<WorkExperienceDto>
}

export const employeeUpdateWorkexperienceToDto = (
    model: EmployeeUpdateWorkExperience
): EmployeeUpdateWorkExperienceDto => {
    return {
        workExperiences: model.workExperiences.map(workExperienceToDto)
    }
}

export const employeeUpdateWorkexperienceFromDto = (
    dto: EmployeeUpdateWorkExperienceDto
): EmployeeUpdateWorkExperience => {
    return {
        workExperiences: dto.workExperiences.map(workExperienceFromDto)
    }
}
