import { animate, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { Component, ContentChild, Directive, EventEmitter, Output, TemplateRef } from '@angular/core'

@Directive({
    selector: '[linkxListItemContextMenu]',
    standalone: true
})
export class ContextMenuListTableItemDirective {
    public constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
    selector: '[linkxListItemContent]',
    standalone: true
})
export class ContentListTableItemDirective {
    public constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
    selector: 'linkx-list-item',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(300, style({ opacity: 0 }))])
        ])
    ]
})
export class ListItemComponent {
    @Output() public itemClicked: EventEmitter<void> = new EventEmitter()
    @ContentChild(ContextMenuListTableItemDirective) public contextmenuListTableItem?: ContextMenuListTableItemDirective

    @ContentChild(ContentListTableItemDirective) public contentListTableItem?: ContentListTableItemDirective
}
