/**
 * This code was taken from https://github.com/ng-matero/extensions/blob/master/projects/extensions/button/button-loading.ts.
 * It messes with the disabled attribute so that a button is always enabled again after loading goes to false again.
 *
 * We simply removed the messing with the disabled attribute to support our use case.
 */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import {
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core'
import { ThemePalette } from '@angular/material/core'
import { MatProgressSpinner } from '@angular/material/progress-spinner'

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `[mat-button][loading],
             [mat-raised-button][loading],
             [mat-stroked-button][loading],
             [mat-flat-button][loading],
             [mat-icon-button][loading],
             [mat-fab][loading],
             [mat-mini-fab][loading]`,
    standalone: true
})
export class ButtonLoadingDirective implements OnChanges {
    public static ngAcceptInputType_loading: BooleanInput

    private spinner!: ComponentRef<MatProgressSpinner> | null

    @Input()
    public get loading(): boolean {
        return this._loading
    }

    public set loading(value: boolean) {
        this._loading = coerceBooleanProperty(value)
    }

    private _loading = false

    @Input() public color: ThemePalette

    public constructor(
        private _elementRef: ElementRef<HTMLButtonElement>,
        private _viewContainerRef: ViewContainerRef,
        private _renderer: Renderer2
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes['loading']) {
            return
        }

        if (changes['loading'].currentValue) {
            this._elementRef.nativeElement.classList.add('mat-button-loading')
            this.createSpinner()
        } else if (!changes['loading'].firstChange) {
            this._elementRef.nativeElement.classList.remove('mat-button-loading')
            this.destroySpinner()
        }
    }

    private createSpinner(): void {
        if (!this.spinner) {
            this.spinner = this._viewContainerRef.createComponent(MatProgressSpinner)
            this.spinner.instance.color = this.color
            this.spinner.instance.diameter = 24
            this.spinner.instance.mode = 'indeterminate'
            this._renderer.appendChild(this._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement)
        }
    }

    private destroySpinner(): void {
        if (this.spinner) {
            this.spinner.destroy()
            this.spinner = null
        }
    }
}
