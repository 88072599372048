export interface PaymentDto {
    transactionCheckoutUrl: string
}

export interface Payment {
    transactionCheckoutUrl: string
}

export const paymentFromDto = (dto: PaymentDto): Payment => {
    return { ...dto }
}
