import { HttpErrorResponse } from '@angular/common/http'
import { ToasterFacade } from '@linkx/shared-toaster'
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs'
import { expectHttpError } from '../functions/expect-http-error'
import { createEmptyPagedEntities, PagedEntities } from './paged-entities'
import { createFailedPagedListResult, PagedList } from './paged-list'

export type AsyncResultStatus = 'pending' | 'failed' | 'success' | 'initial'

export interface AsyncResult<TResult, TFilter = null> {
    status: AsyncResultStatus
    error?: Error
    entity: TResult
    filters: TFilter | null
}

export const createInitialAsyncResult = <TResult, TFilter = null>(
    entities: TResult,
    filters: TFilter | null = null
): AsyncResult<TResult, TFilter> => {
    return {
        status: 'initial',
        entity: entities,
        filters
    }
}

export const createPendingAsyncResult = <TResult, TFilter = null>(
    entities: TResult,
    filters: TFilter | null = null
): AsyncResult<TResult, TFilter> => {
    return {
        status: 'pending',
        entity: entities,
        filters
    }
}

export const createSuccessAsyncResult = <TResult, TFilter = null>(
    entities: TResult,
    filters: TFilter | null = null
): AsyncResult<TResult, TFilter> => {
    return {
        status: 'success',
        entity: entities,
        filters
    }
}

export const createFailedAsyncResult = <TResult, TFilter = null>(
    error: Error,
    entities: TResult,
    filters: TFilter | null = null
): AsyncResult<TResult, TFilter> => {
    return {
        status: 'failed',
        error,
        entity: entities,
        filters
    }
}

export const asyncResultHasFinished = <TResult, TFilter = null>(
    asyncResult: AsyncResult<TResult, TFilter>
): boolean => {
    return asyncResult.status === 'success' || asyncResult.status === 'failed'
}

export const asyncResultIsPending = <TResult, TFilter = null>(asyncResult: AsyncResult<TResult, TFilter>): boolean => {
    return asyncResult.status === 'pending'
}

export const extractHttpError = (httpError: HttpErrorResponse): Error => {
    if (httpError.error && typeof httpError.error === 'object') {
        if (Array.isArray(httpError.error.messages) && httpError.error.messages.length > 0) {
            const messageObject = httpError.error.messages[0]
            return new Error(messageObject.text)
        }

        if (httpError.error.Message) {
            return new Error(httpError.error.Message)
        }
    }

    return new Error(httpError.error)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const expectAsyncResultHttpError = <TEntity>(statusCodes: Array<number>, fallbackValue: TEntity) =>
    expectHttpError(statusCodes, (httpError: HttpErrorResponse) => {
        const error = extractHttpError(httpError)
        return createFailedAsyncResult(error, fallbackValue, null)
    })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const expectPagedListHttpSuccess = <TEntity, TFilters>(
    successHandler: (pagedList: PagedList<TEntity, TFilters>) => void,
    filters?: TFilters
) =>
    tap((entities: PagedEntities<TEntity>) => {
        successHandler(createSuccessAsyncResult<PagedEntities<TEntity>, TFilters>(entities, filters))
    })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const expectPagedListHttpError = <TEntity, TFilters>(
    statusCodes: Array<number>,
    emptyPagedListHandler: (pagedList: PagedList<TEntity, TFilters>) => void,
    filters?: TFilters
) =>
    expectHttpError(statusCodes, (httpError: HttpErrorResponse) => {
        const error = extractHttpError(httpError)
        const pagedList = createFailedPagedListResult<TEntity, TFilters>(
            error,
            createEmptyPagedEntities<TEntity>(),
            filters
        )

        emptyPagedListHandler(pagedList)
    })

export const executeAsyncOperation = async <TAsyncResult, TAsyncFilter>(
    operation$: Observable<AsyncResult<TAsyncResult, TAsyncFilter>>,
    handlers: {
        success: (result: AsyncResult<TAsyncResult, TAsyncFilter>) => void
        error: (result: AsyncResult<TAsyncResult, TAsyncFilter>) => void
    },
    isExecuting$: BehaviorSubject<boolean>
): Promise<AsyncResult<TAsyncResult, TAsyncFilter>> => {
    isExecuting$.next(true)
    const result = await firstValueFrom(operation$)
    isExecuting$.next(false)

    if (result.status === 'success') {
        handlers.success(result)
    } else if (result.status === 'failed') {
        handlers.error(result)
    }

    return result
}

export const showAsyncResultErrorToast = <TResult, TFilter>(toastFacade: ToasterFacade) => {
    return (result: AsyncResult<TResult, TFilter>) => {
        toastFacade.showError(result.error?.message ?? 'unexpected-error')
    }
}
