import { DateTime } from 'luxon'

export interface CreateBooking {
    description: string
    employeeId: number
    locationRemarks: string
    numberOfHours: number
    startDate: DateTime
    qualificationId: number
}

export interface CreateBookingDto {
    description: string
    employeeId: number
    locationRemarks: string
    numberOfMinutes: number
    startDate: string
    qualificationId: number
}

export const createBookingToDto = (createBooking: CreateBooking): CreateBookingDto => {
    return {
        ...createBooking,
        numberOfMinutes: createBooking.numberOfHours * 60,
        startDate: createBooking.startDate.toUTC().toISO()
    }
}

export const createBookingFromDto = (createBookingDto: CreateBookingDto): CreateBooking => {
    return {
        ...createBookingDto,
        numberOfHours: createBookingDto.numberOfMinutes / 60,
        startDate: DateTime.fromISO(createBookingDto.startDate)
    }
}
