import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { CognitoUserInfo, LinkxAuthFacade } from '@linkx/shared-auth'
import { Observable } from 'rxjs'

@Component({
    selector: 'linkx-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {
    @Input() public showMenuToggle = true
    @Input() public isSidenavOpen = true
    @Output() public toggleSidebar: EventEmitter<void> = new EventEmitter<void>()

    public auth: LinkxAuthFacade = inject(LinkxAuthFacade)

    public currentUser$: Observable<CognitoUserInfo | null> = this.auth.getCurrentUser()
}
