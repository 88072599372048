import { PagedList } from '../types/paged-list'

export const EMPTY_PAGED_LIST_SUCCESS: PagedList<unknown, unknown> = {
    entity: {
        items: [],
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0,
        hasNextPage: false,
        totalPages: 1,
        hasPreviousPage: false
    },
    status: 'success',
    filters: null
}

export const EMPTY_PAGED_LIST_INITIAL: PagedList<unknown, unknown> = {
    entity: {
        items: [],
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0,
        hasNextPage: false,
        totalPages: 1,
        hasPreviousPage: false
    },
    status: 'initial',
    filters: null
}
