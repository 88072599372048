import { Selector } from '@ngxs/store'
import { LinkxUserInfo } from '../linkx-user-info'
import { AuthState, AuthStateModel } from './auth.state'

export class AuthSelector {
    @Selector([AuthState])
    public static authenticatedUser(state: AuthStateModel): LinkxUserInfo | null {
        return state.authenticatedUser
    }
}
