import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { linkxEmployeeGuard } from './linkx-profile-guard'

export const employeeMyProfileGuard = (snapshot: ActivatedRouteSnapshot): Observable<boolean> => {
    // If we have a token in the query params, we will use that token to make requests.
    // If no token is provided, we require the user to be authenticated as an employee.
    if (snapshot.queryParams['token']) {
        return of(true)
    }

    return linkxEmployeeGuard()
}
