import { inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { MetadataService } from '../metadata/metadata.service'
import { notUndefined } from '@ppwcode/js-ts-oddsandends/lib/conditional-assert'

@Injectable({ providedIn: 'root' })
export class LanguageFacade {
    private metadata: MetadataService = inject(MetadataService)

    public getLanguages(): Observable<Array<string>> {
        return of(this.metadata.state.languages)
    }

    public getLanguageTranslations(language: string): Record<string, string> {
        return notUndefined(this.metadata.state.languagesTranslations[language])
    }
}
