<button class="sidenav-close-button" *ngIf="showMenuCloseButton" (click)="closeSidebar.emit()">
    <mat-icon>close</mat-icon>
</button>
<mat-card *ngIf="currentUser$ | async as currentUser; else noUser" class="user-card">
    <mat-card-content>
        <div class="user-card-first-row">
            <div class="profile-avatar authenticated">
                <span class="name"
                    >{{ currentUser.givenName | slice: 0:1 }}{{ currentUser.familyName | slice: 0:1 }}</span
                >
            </div>

            <div class="user-level-balance" *ngIf="currentUserProfile$ | async as userProfile">
                <span class="user-level">
                    <ng-container [ngSwitch]="userProfile.userLevel">
                        <i class="fas fa-user-shield" *ngSwitchCase="'ADMIN'"></i>
                        <i class="fas fa-person-digging" *ngSwitchCase="'EMPLOYEE'"></i>
                        <i class="fas fa-user-tie" *ngSwitchCase="'EMPLOYER'"></i>
                        <i class="fas fa-user-shield" *ngSwitchCase="'SUPERADMIN'"></i>
                    </ng-container>
                    {{ 'user-level.' + userProfile.userLevel | translate }}
                </span>
                <span class="user-balance" *ngIf="userProfile.balance !== null">
                    {{ userProfile.balance | currency: 'EUR' }}
                </span>
            </div>
        </div>
        <p class="authenticated-with">
            {{ currentUser.email }}<br />{{
                'auth.authenticated-with' | translate: { provider: currentUser.identityProvider }
            }}
        </p>
    </mat-card-content>
</mat-card>

<ng-container *ngTemplateOutlet="sidebarMenu"></ng-container>
<ng-template #sidebarMenu>
    <mat-nav-list>
        <mat-list-item
            *ngFor="let navigationItem of navigationItems"
            [disabled]="navigationItem.isEnabled === false"
            [routerLink]="navigationItem.fullRouterPath">
            <mat-icon matListItemIcon>{{ navigationItem.icon }}</mat-icon>
            <div matListItemTitle>{{ navigationItem.label | translate }}</div>
        </mat-list-item>
    </mat-nav-list>
</ng-template>

<ng-template #noUser>
    <mat-card class="user-card">
        <mat-card-content>
            <div class="user-card-first-row">
                <div class="profile-avatar not-authenticated">
                    <i class="fa-solid fa-user-large-slash"></i>
                </div>
            </div>
            <p class="authenticated-with">{{ 'auth.not-authenticated' | translate }}</p>
        </mat-card-content>
    </mat-card>
</ng-template>
