import { DateTime } from 'luxon'
import { BookingStatus } from '../booking-status.enum'

export interface EmployeeBookingFilterDto extends Record<string, unknown> {
    bookingStatuses: Array<BookingStatus>
    start: string | null
    end: string | null
}

export interface EmployeeBookingFilter {
    [BookingStatus.CANCELED]: boolean
    [BookingStatus.CLOSED]: boolean
    [BookingStatus.CONFIRMED_BY_EMPLOYEE]: boolean
    [BookingStatus.INITIATED]: boolean
    [BookingStatus.INTERRUPTED]: boolean
    [BookingStatus.PAID]: boolean
    [BookingStatus.PAYMENT_INITIATED]: boolean
    start: DateTime | null
    end: DateTime | null
}

export const employeeBookingFilterToDto = (model: EmployeeBookingFilter): EmployeeBookingFilterDto => {
    const bookingStatusKeys = Object.keys(BookingStatus)
    const statuses = Object.keys(model)
        .filter((key) => bookingStatusKeys.indexOf(key) > -1 && !!model[key as keyof EmployeeBookingFilter])
        .map((key) => BookingStatus[key as keyof typeof BookingStatus])

    return {
        bookingStatuses: statuses,
        start: model.start ? model.start.toUTC().toISO() : null,
        end: model.end ? model.end.toUTC().toISO() : null
    }
}
