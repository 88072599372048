import { CognitoUserInfo } from './cognito-user-info'

declare type UserLevel = 'NONE' | 'ADMIN' | 'SUPER_ADMIN'

export interface LinkxUserInfoDto {
    id: number
    balance: number | null
    email: string
    cognitoTokenSub: string
    cognitoIdp: string
    employeeId: number | null
    employerId: number | null
    notificationTokens: Array<string>
    languageCode: string
    userLevel: UserLevel
    redirectTag: string
}

export interface LinkxUserInfo {
    id: number
    balance: number | null
    email: string
    employeeId: number | null
    employerId: number | null
    language: string
    redirectTag: string
    userLevel: UserLevel
    cognito: LinkxUserCognitoInfo
}

export interface LinkxUserCognitoInfo {
    tokenSub: string
    idp: string
    userInfo: CognitoUserInfo | null
}

export const linkxUserInfoFromDto = (dto: LinkxUserInfoDto, cognitoUserInfo: CognitoUserInfo | null): LinkxUserInfo => {
    return {
        id: dto.id,
        balance: dto.balance,
        email: dto.email,
        employeeId: dto.employeeId,
        employerId: dto.employerId,
        language: dto.languageCode,
        redirectTag: dto.redirectTag,
        userLevel: dto.userLevel,
        cognito: {
            tokenSub: dto.cognitoTokenSub,
            idp: dto.cognitoIdp,
            userInfo: cognitoUserInfo
        }
    }
}
