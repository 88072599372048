import { inject, Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Duration } from 'luxon'

@Pipe({
    name: 'luxonDuration',
    standalone: true
})
export class LuxonDurationPipe implements PipeTransform {
    private translateService: TranslateService = inject(TranslateService)

    public transform(value: Duration | number | null): string {
        if (typeof value === 'number' || value === null) {
            const hours: number = Math.floor(value == null ? 0 : value / 60)
            const minutes: number = Math.floor(value == null ? 0 : value % 60)
            value = Duration.fromObject({ hours, minutes })
        }
        return this.translateService.instant('duration.hours-and-minutes', value)
    }
}
