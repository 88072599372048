import { Language, LanguageDto, languageFromDto, languageToDto } from '../../language/language.model'
import { WorkExperience } from '../../workexperience/workexperience.model'

export interface EmployeeSearch {
    id: number
    firstName: string
    lastName: string
    workExperiences: Array<WorkExperience>
    city: string
    languages: Array<Language>
}

export interface EmployeeSearchDto {
    id: number
    firstName: string
    lastName: string
    workExperiences: Array<WorkExperience>
    city: string
    languages: Array<LanguageDto>
}

export const employeeSearchToDto = (employeeSearch: EmployeeSearch): EmployeeSearchDto => {
    return {
        ...employeeSearch,
        languages: employeeSearch.languages.map(languageToDto)
    }
}

export const employeeSearchFromDto = (employeeSearchDto: EmployeeSearchDto): EmployeeSearch => {
    return {
        ...employeeSearchDto,
        languages: employeeSearchDto.languages.map(languageFromDto)
    }
}
