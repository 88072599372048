import { Employer } from '../employer.model'
import { PagedList } from '@linkx/shared-utils'
import { EmployerListFilter } from '../employer-list-filter/employer-list-filter.model'
import { Transactionlog } from '../../transactionlog/transactionlog.model'
import { MyTransactionlogsListFilter } from '../../transactionlog/my-transactionlogs-filter/my-transactionlogs-list-filter.model'

export class EmployersLoaded {
    public static readonly type = '[Employers] Employers loaded'
    public constructor(public employers: PagedList<Employer, EmployerListFilter>) {}
}

export class EmployerTransactionlogsLoaded {
    public static readonly type = '[Booking] Employer transactionlogs loaded'
    public constructor(public transactionlogs: PagedList<Transactionlog, MyTransactionlogsListFilter>) {}
}

export class EmployerUpdated {
    public static readonly type = '[Employers] Employers updated'
    public constructor(public employer: Employer) {}
}

export class EmployerInserted {
    public static readonly type = '[Employers] Employers inserted'
    public constructor(public employer: Employer) {}
}

export class ClearEmployers {
    public static readonly type = '[Employers] Employers cleared'
}
