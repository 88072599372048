import { PagedList } from '@linkx/shared-utils'
import { BookingItem } from '../booking/booking-item.model'
import { BookingListFilter } from '../booking/booking-list-filter.model'
import { Booking } from '../booking/booking.model'
import { EmployeeBookingFilter } from '../booking/employee-booking-filter.model'
import { EmployeeBooking } from '../booking/employee-booking.model'
import { EmployerBookingFilter } from '../booking/employer-booking-filter.model'
import { EmployerBooking } from '../booking/employer-booking.model'
import { MyBookingsListFilter } from '../mybooking-filter/my-bookings-list-filter.model'
import { MyBookingsListItem } from '../mybooking/my-bookings-list-item.model'

export class MyBookingsLoaded {
    public static readonly type = '[Bookings] MyBookings loaded'
    public constructor(public bookings: PagedList<MyBookingsListItem, MyBookingsListFilter>) {}
}

export class BookingsLoaded {
    public static readonly type = '[Bookings] Bookings loaded'
    public constructor(public bookings: PagedList<BookingItem, BookingListFilter>) {}
}

export class EmployerBookingsLoaded {
    public static readonly type = '[Bookings] Employer bookings loaded'
    public constructor(public bookings: PagedList<EmployerBooking, EmployerBookingFilter>) {}
}

export class EmployeeBookingsLoaded {
    public static readonly type = '[Bookings] Employee bookings loaded'
    public constructor(public bookings: PagedList<EmployeeBooking, EmployeeBookingFilter>) {}
}

export class ClearMyBookings {
    public static readonly type = '[Bookings] MyBookings cleared'
}

export class ClearBookings {
    public static readonly type = '[Bookings] Bookings cleared'
}

export class BookingSelected {
    public static readonly type = '[Booking] Booking selected'
    public constructor(public booking: Booking) {}
}

export class SelectedBookingCleared {
    public static readonly type = '[Booking] Selected booking cleared'
}

export class StateCleared {
    public static readonly type = '[Booking] State cleared'
}
