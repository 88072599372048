import { DateTime } from 'luxon'
import { Address, AddressDto, addressFromDto, addressToDto } from '../address/address.model'
import { Certificate, CertificateDto, certificateFromDto, certificateToDto } from '../certificate/certificate.model'
import { Company, CompanyDto, companyFromDto, companyToDto } from '../company/company.model'
import { Language, LanguageDto, languageFromDto, languageToDto } from '../language/language.model'
import {
    WorkExperience,
    WorkExperienceDto,
    workExperienceFromDto,
    workExperienceToDto
} from '../workexperience/workexperience.model'

export interface Employee {
    id: number | null
    lastName: string
    firstName: string
    birthDate: DateTime
    birthPlace: string
    email: string
    phoneNumber: string
    address: Address
    company: Company
    hoursPerDayAvailable: number
    needsBed: boolean
    needsTransportation: boolean
    canWorkOnSaturday: boolean
    canWorkOnSunday: boolean
    canWorkOnHoliday: boolean
    languages: Array<Language>
    workExperiences: Array<WorkExperience>
    certificates: Array<Certificate>
}

export interface EmployeeDto {
    id: number | null
    lastName: string
    firstName: string
    birthDate: string
    birthPlace: string
    email: string
    phoneNumber: string
    personalAddress: AddressDto
    business: CompanyDto
    hoursPerDayAvailable: number
    needsBed: boolean
    needsTransportation: boolean
    canWork: {
        onSaturday: boolean
        onSunday: boolean
        onHoliday: boolean
    }
    languages: Array<LanguageDto>
    workExperiences: Array<WorkExperienceDto>
    certificates: Array<CertificateDto>
}

export const employeeToDto = (employee: Employee): EmployeeDto => {
    return {
        birthPlace: employee.birthPlace,
        email: employee.email,
        firstName: employee.firstName,
        hoursPerDayAvailable: employee.hoursPerDayAvailable,
        id: employee.id,
        lastName: employee.lastName,
        needsBed: employee.needsBed,
        needsTransportation: employee.needsTransportation,
        phoneNumber: employee.phoneNumber,
        personalAddress: addressToDto(employee.address),
        business: companyToDto(employee.company),
        birthDate: employee.birthDate.toISODate(),
        canWork: {
            onSaturday: employee.canWorkOnSaturday,
            onSunday: employee.canWorkOnSunday,
            onHoliday: employee.canWorkOnHoliday
        },
        languages: employee.languages.map(languageToDto),
        workExperiences: employee.workExperiences.map(workExperienceToDto),
        certificates: employee.certificates.map(certificateToDto)
    }
}

export const employeeFromDto = (employeeDTO: EmployeeDto): Employee => {
    return {
        birthPlace: employeeDTO.birthPlace,
        email: employeeDTO.email,
        firstName: employeeDTO.firstName,
        hoursPerDayAvailable: employeeDTO.hoursPerDayAvailable,
        id: employeeDTO.id,
        lastName: employeeDTO.lastName,
        needsBed: employeeDTO.needsBed,
        needsTransportation: employeeDTO.needsTransportation,
        phoneNumber: employeeDTO.phoneNumber,
        address: addressFromDto(employeeDTO.personalAddress),
        company: companyFromDto(employeeDTO.business),
        birthDate: DateTime.fromISO(employeeDTO.birthDate),
        canWorkOnSaturday: employeeDTO.canWork.onSaturday,
        canWorkOnSunday: employeeDTO.canWork.onSunday,
        canWorkOnHoliday: employeeDTO.canWork.onHoliday,
        languages: employeeDTO.languages.map(languageFromDto),
        workExperiences: employeeDTO.workExperiences.map(workExperienceFromDto),
        certificates: employeeDTO.certificates.map(certificateFromDto)
    }
}
