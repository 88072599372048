import { Route } from '@angular/router'
import { linkxEmployerGuard, linkxProfileGuard, WelcomeComponent } from '@linkx/shared-auth'

export const appRoutes: Route[] = [
    {
        path: 'employer',
        loadChildren: () =>
            import('@linkx/frontoffice-employer-feature').then((m) => m.FrontofficeEmployerFeatureModule)
        // Access to this module is always allowed. The employer/register path should be anonymously available.
    },
    {
        path: 'my-bookings',
        loadChildren: () => import('@linkx/frontoffice-booking-feature').then((m) => m.FrontofficeBookingFeatureModule),
        canActivate: [linkxProfileGuard]
    },
    {
        path: 'employee',
        loadChildren: () =>
            import('@linkx/frontoffice-employee-feature').then((m) => m.FrontofficeEmployeeFeatureModule)
    },
    {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [linkxProfileGuard]
    },
    {
        path: 'afterpayment/:bookingId',
        loadComponent: () => import('@linkx/frontoffice-payment-feature').then((c) => c.AfterPaymentPageComponent),
        canActivate: [linkxEmployerGuard]
    },
    {
        path: '**',
        redirectTo: 'employee'
    }
]
