import { Injectable } from '@angular/core'
import { createEmptyPagedEntities, PagedList } from '@linkx/shared-utils'
import { Action, State, StateContext } from '@ngxs/store'
import { BookingItem } from '../booking/booking-item.model'
import { BookingListFilter } from '../booking/booking-list-filter.model'
import { Booking } from '../booking/booking.model'
import { EmployeeBookingFilter } from '../booking/employee-booking-filter.model'
import { EmployeeBooking } from '../booking/employee-booking.model'
import { EmployerBookingFilter } from '../booking/employer-booking-filter.model'
import { EmployerBooking } from '../booking/employer-booking.model'
import { MyBookingsListFilter } from '../mybooking-filter/my-bookings-list-filter.model'
import { MyBookingsListItem } from '../mybooking/my-bookings-list-item.model'
import {
    BookingSelected,
    BookingsLoaded,
    ClearBookings,
    ClearMyBookings,
    EmployeeBookingsLoaded,
    EmployerBookingsLoaded,
    MyBookingsLoaded,
    SelectedBookingCleared,
    StateCleared
} from './booking.action'

export interface BookingStateModel {
    myBookings: PagedList<MyBookingsListItem, MyBookingsListFilter>
    bookings: PagedList<BookingItem, BookingListFilter>
    employerBookings: PagedList<EmployerBooking, EmployerBookingFilter>
    employeeBookings: PagedList<EmployeeBooking, EmployeeBookingFilter>
    selectedBooking: Booking | null
}

export const BOOKING_STATE_DEFAULTS: BookingStateModel = {
    myBookings: {
        entity: {
            items: [],
            hasNextPage: false,
            pageSize: 20,
            pageIndex: 1,
            hasPreviousPage: false,
            totalCount: 0,
            totalPages: 1
        },
        status: 'pending',
        filters: null
    },
    bookings: {
        entity: {
            items: [],
            hasNextPage: false,
            pageSize: 20,
            pageIndex: 1,
            hasPreviousPage: false,
            totalCount: 0,
            totalPages: 1
        },
        status: 'pending',
        filters: null
    },
    employerBookings: {
        entity: createEmptyPagedEntities(),
        status: 'pending',
        filters: null
    },
    employeeBookings: {
        entity: createEmptyPagedEntities(),
        status: 'pending',
        filters: null
    },
    selectedBooking: null
}

@State<BookingStateModel>({
    name: 'bookings',
    defaults: BOOKING_STATE_DEFAULTS
})
@Injectable()
export class BookingsState {
    @Action(MyBookingsLoaded)
    public myBookingsLoaded(ctx: StateContext<BookingStateModel>, action: MyBookingsLoaded): void {
        ctx.setState({
            ...ctx.getState(),
            myBookings: action.bookings
        })
    }

    @Action(BookingsLoaded)
    public bookingsLoaded(ctx: StateContext<BookingStateModel>, action: BookingsLoaded): void {
        ctx.setState({ ...ctx.getState(), bookings: action.bookings })
    }

    @Action(EmployerBookingsLoaded)
    public employerBookingsLoaded(ctx: StateContext<BookingStateModel>, action: EmployerBookingsLoaded): void {
        ctx.patchState({ employerBookings: action.bookings })
    }

    @Action(EmployeeBookingsLoaded)
    public employeeBookingsLoaded(ctx: StateContext<BookingStateModel>, action: EmployeeBookingsLoaded): void {
        ctx.patchState({ employeeBookings: action.bookings })
    }

    @Action(ClearMyBookings)
    public clearMyBookings(ctx: StateContext<BookingStateModel>): void {
        ctx.setState({
            ...ctx.getState(),
            myBookings: {
                status: 'pending',
                filters: null,
                entity: {
                    items: [],
                    totalPages: 0,
                    totalCount: 0,
                    hasPreviousPage: false,
                    pageIndex: 1,
                    pageSize: 20,
                    hasNextPage: false
                }
            }
        })
    }

    @Action(ClearBookings)
    public clearBookings(ctx: StateContext<BookingStateModel>): void {
        ctx.setState({
            ...ctx.getState(),
            bookings: {
                status: 'pending',
                filters: null,
                entity: {
                    items: [],
                    totalPages: 0,
                    totalCount: 0,
                    hasPreviousPage: false,
                    pageIndex: 1,
                    pageSize: 20,
                    hasNextPage: false
                }
            }
        })
    }

    @Action(BookingSelected)
    public bookingSelected(ctx: StateContext<BookingStateModel>, action: BookingSelected): void {
        ctx.setState({ ...ctx.getState(), selectedBooking: action.booking })
    }

    @Action(SelectedBookingCleared)
    public clearSelectedBooking(ctx: StateContext<BookingStateModel>): void {
        ctx.setState({ ...ctx.getState(), selectedBooking: null })
    }

    @Action(StateCleared)
    public clearState(ctx: StateContext<BookingStateModel>): void {
        ctx.setState(BOOKING_STATE_DEFAULTS)
    }
}
