import { minutesBetween } from '@linkx/shared-utils'
import { DateTime } from 'luxon'

export interface Timeslice {
    id: number | null
    startTime: DateTime
    endTime: DateTime | null
    duration: number | null
    bookingId: number | null
}

export interface TimesliceDto {
    id: number | null
    startTime: string
    endTime: string | null
    duration: null
    bookingId: number | null
}

export const timesliceToDto = (timeslice: Timeslice): TimesliceDto => {
    return {
        ...timeslice,
        startTime: timeslice.startTime.toUTC().toISO(),
        endTime: timeslice.endTime !== null ? timeslice.endTime.toUTC().toISO() : null,
        duration: null
    }
}

export const timesliceFromDto = (timesliceDto: TimesliceDto): Timeslice => {
    const startTime: DateTime = DateTime.fromISO(timesliceDto.startTime)
    const endTime: DateTime | null = timesliceDto.endTime ? DateTime.fromISO(timesliceDto.endTime) : null
    const duration: number | null = endTime ? minutesBetween(startTime, endTime) : null

    return {
        ...timesliceDto,
        startTime,
        endTime,
        duration
    }
}
