import { inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { MetadataService } from '../metadata/metadata.service'

@Injectable({ providedIn: 'root' })
export class CertificateFacade {
    private metadata: MetadataService = inject(MetadataService)

    public getCertificates(): Observable<Array<string>> {
        return of(this.metadata.state.certificates)
    }
}
