<mat-form-field (blur)="blur()">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mtx-datetimepicker
        #datetimePickerEnd
        type="datetime"
        mode="portrait"
        startView="month"
        [timeInput]="true"></mtx-datetimepicker>

    <input
        [mtxDatetimepicker]="datetimePickerEnd"
        [min]="$any(min)"
        [max]="$any(max)"
        [formControl]="control"
        matInput />
    <mtx-datetimepicker-toggle [for]="datetimePickerEnd" matSuffix></mtx-datetimepicker-toggle>
</mat-form-field>
