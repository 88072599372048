import { Employer, EmployerDto, employerToDto } from '@linkx/shared-data-access'

export interface CreateOrUpdateUserEmployerDto {
    employer: EmployerDto
    redirectTag: string
    email: string
    phoneNumber: string
    languageCode: string
    refreshToken: string
}

export interface CreateOrUpdateUserEmployer {
    employer: Employer
    redirectTag: string
    email: string
    phoneNumber: string
    languageCode: string
    refreshToken: string
}

export const createOrUpdateUserEmployerToDto = (model: CreateOrUpdateUserEmployer): CreateOrUpdateUserEmployerDto => {
    return {
        ...model,
        employer: employerToDto(model.employer)
    }
}
