import { inject, Injectable } from '@angular/core'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    expectAsyncResultHttpError,
    expectPagedListHttpError,
    expectPagedListHttpSuccess,
    isPagedListSameQuery,
    PagedList
} from '@linkx/shared-utils'
import { Select, Store } from '@ngxs/store'
import { map, Observable, switchMap, take, tap } from 'rxjs'
import { BackofficeEmployeesLoaded, EmployeeSearchInserted, EmployeeUpdated } from '../+state/employee.action'
import { EmployeeSelector } from '../+state/employee.selector'
import { EmployeeEducation } from '../employee-education/employee-education'
import { EmployeeListFilter } from '../employee-list/employee-list-filter.model'
import { EmployeeSearch } from '../employee-search/employee-search.model'
import { EmployeeUpdateWorkExperience } from '../employee-workexperience/employee-workexperience.model'
import { Employee } from '../employee.model'
import { BackOfficeEmployeeService } from '../services/backoffice-employee.service'

@Injectable({ providedIn: 'root' })
export class BackOfficeEmployeeFacade {
    // Stream containing the list of employees in the state.
    @Select(EmployeeSelector.backofficeEmployees)
    private readonly employees$!: Observable<PagedList<EmployeeSearch, EmployeeListFilter>>

    private backOfficeEmployeeService: BackOfficeEmployeeService = inject(BackOfficeEmployeeService)
    private store: Store = inject(Store)

    public getEmployees(
        pageIndex: number,
        pageSize: number,
        searchCriteria: EmployeeListFilter
    ): Observable<PagedList<EmployeeSearch, EmployeeListFilter>> {
        const dispatchLoaded = (pl: PagedList<EmployeeSearch, EmployeeListFilter>): Observable<unknown> =>
            this.store.dispatch(new BackofficeEmployeesLoaded(pl))

        return this.employees$.pipe(
            take(1),
            switchMap((employeeList: PagedList<EmployeeSearch, EmployeeListFilter>) => {
                if (isPagedListSameQuery(employeeList, pageIndex, searchCriteria)) {
                    return this.employees$
                }

                return this.backOfficeEmployeeService.getAll(pageIndex, pageSize, searchCriteria).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded, searchCriteria),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded, searchCriteria),
                    switchMap(() => this.employees$)
                )
            })
        )
    }

    public getEmployeeById(id: number): Observable<AsyncResult<Employee | null>> {
        return this.backOfficeEmployeeService.getById(id).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public insertEmployee(employee: Employee): Observable<AsyncResult<Employee | null>> {
        return this.backOfficeEmployeeService.insert(employee).pipe(
            tap((newEmployee) => this.store.dispatch(new EmployeeSearchInserted(newEmployee))),
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public updateEmployee(employee: Employee): Observable<AsyncResult<Employee | null>> {
        return this.backOfficeEmployeeService.update(employee).pipe(
            tap((updatedEmployee) => this.store.dispatch(new EmployeeUpdated(updatedEmployee))),
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public updateEmployeeEducation(
        employeeId: number,
        education: EmployeeEducation
    ): Observable<AsyncResult<EmployeeEducation | null>> {
        return this.backOfficeEmployeeService.updateEducation(employeeId, education).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public updateEmployeeWorkExperience(
        employeeId: number,
        workExperience: EmployeeUpdateWorkExperience
    ): Observable<AsyncResult<EmployeeUpdateWorkExperience | null>> {
        return this.backOfficeEmployeeService.updateWorkExperience(employeeId, workExperience).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }
}
