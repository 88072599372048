import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'linkx-country-flag',
    templateUrl: './country-flag.component.html',
    styleUrls: ['./country-flag.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class CountryFlagComponent implements OnInit {
    @Input() public flagCode?: string

    // A required conversion is needed because of a mismatch in country codes between the flag-icons dependency and metadata backend
    public differentFlags = new Map([
        ['en', 'gb'],
        ['cs', 'cz'],
        ['uk', 'ua']
    ])
    public ngOnInit(): void {
        if (this.flagCode !== undefined && this.differentFlags.has(this.flagCode)) {
            this.flagCode = this.differentFlags.get(this.flagCode)
        }
    }
}
