export interface Qualification {
    id: number
    qualificationName: string
}

export interface QualificationDto {
    id: number
    qualificationName: string
}

export const qualificationToDto = (qualification: Qualification): QualificationDto => {
    return {
        ...qualification
    }
}

export const qualificationFromDto = (qualificationDto: QualificationDto): Qualification => {
    return {
        ...qualificationDto
    }
}
