import { DateTime } from 'luxon'
import { Timeslice, TimesliceDto, timesliceFromDto, timesliceToDto } from '../../timeslice/timeslice.model'

export interface UpdateBookingAsAdmin {
    feePct: number
    vatPct: number
    startDate: DateTime
    description: string
    approvedByEmployee: boolean
    employeeId: number
    hourlyWage: number
    id: number
    locationRemarks: string
    numberOfHours: number
    remainingBalance: number
    remarks: string
    timeslices: Array<Timeslice>
}

export interface UpdateBookingAsAdminDto {
    feePct: number
    vatPct: number
    startDate: string
    description: string
    approvedByEmployee: boolean
    employeeId: number
    hourlyWage: number
    id: number
    locationRemarks: string
    numberOfMinutes: number
    remainingBalance: number
    remarks: string
    timeslices: Array<TimesliceDto>
}

export const updateBookingAsAdminToDto = (updateBookingAsAdmin: UpdateBookingAsAdmin): UpdateBookingAsAdminDto => {
    return {
        ...updateBookingAsAdmin,
        numberOfMinutes: updateBookingAsAdmin.numberOfHours * 60,
        startDate: updateBookingAsAdmin.startDate.toUTC().toISO(),
        timeslices: updateBookingAsAdmin.timeslices.map(timesliceToDto)
    }
}

export const updateBookingAsAdminFromDto = (updateBookingAsAdminDto: UpdateBookingAsAdminDto): UpdateBookingAsAdmin => {
    return {
        ...updateBookingAsAdminDto,
        numberOfHours: updateBookingAsAdminDto.numberOfMinutes * 60,
        startDate: DateTime.fromISO(updateBookingAsAdminDto.startDate),
        timeslices: updateBookingAsAdminDto.timeslices.map(timesliceFromDto)
    }
}
