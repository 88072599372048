import { inject, Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Pipe({
    name: 'safeHtml',
    standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
    public sanitizer: DomSanitizer = inject(DomSanitizer)
    public transform(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value)
    }
}
