import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatSidenavModule } from '@angular/material/sidenav'
import { RouterLink, RouterOutlet } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { AppWireframeComponent } from './app-wireframe/app-wireframe.component'
import { LeftSidenavComponent } from './left-sidenav/left-sidenav.component'
import { ToolbarComponent } from './toolbar/toolbar.component'

@NgModule({
    imports: [
        CommonModule,
        RouterOutlet,
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        TranslateModule,
        RouterLink,
        MatCardModule
    ],
    exports: [AppWireframeComponent],
    declarations: [ToolbarComponent, LeftSidenavComponent, AppWireframeComponent]
})
export class SharedWireframeModule {}
