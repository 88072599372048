<mtx-loader [loading]="pending" type="progressbar">
    <ng-container *ngIf="asyncResult">
        <ng-container
            *ngTemplateOutlet="
                hasFailed ? failed : isInitial ? initialTemplate ?? null : successTemplate;
                context: { $implicit: asyncResult }
            ">
        </ng-container>
    </ng-container>
</mtx-loader>

<ng-template #failed>
    <linkx-message-bar
        [message]="asyncResult!.error?.message ?? '' | translate"
        [severity]="errorSeverity"></linkx-message-bar>
</ng-template>
