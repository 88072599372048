import { inject, Injectable } from '@angular/core'
import { notUndefined } from '@ppwcode/js-ts-oddsandends/lib/conditional-assert'
import { Observable, of } from 'rxjs'
import { MetadataService } from '../metadata/metadata.service'

@Injectable({ providedIn: 'root' })
export class CountryFacade {
    private metadata: MetadataService = inject(MetadataService)

    public getCountries(): Observable<Array<string>> {
        return of(this.metadata.state.countries.filter((country) => country.toLowerCase() !== 'none'))
    }

    public getCountryTranslations(language: string): Record<string, string> {
        return notUndefined(this.metadata.state.countriesTranslations[language])
    }
}
