import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import {
    APP_CONFIG,
    AppConfig,
    extractPrimitiveNonNullProperties,
    PagedEntities,
    pagedEntitiesFromDto
} from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import {
    BookingPaymentStatus,
    BookingPaymentStatusDto,
    bookingPaymentStatusFromDto
} from '../booking/booking-payment-status'
import { Booking, BookingDto, bookingFromDto } from '../booking/booking.model'
import { CreateBooking, createBookingToDto } from '../booking/create-booking.model'
import { UpdateBooking, updateBookingToDto } from '../booking/update-booking.model'
import {
    MyBookingsListItem,
    MyBookingsListItemDto,
    myBookingsListItemFromDto
} from '../mybooking/my-bookings-list-item.model'
import { MyBookingsListFilter, myBookingsListFilterToDto } from '../mybooking-filter/my-bookings-list-filter.model'

@Injectable({
    providedIn: 'root'
})
export class FrontofficeBookingService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)

    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/frontoffice/bookings`
    }

    public getById(bookingId: number): Observable<Booking> {
        return this.http.get<BookingDto>(`${this.basePath}/${bookingId}`).pipe(map(bookingFromDto))
    }

    public getPaymentStatus(bookingId: number): Observable<BookingPaymentStatus> {
        return this.http
            .get<BookingPaymentStatusDto>(`${this.basePath}/${bookingId}/payment/status`)
            .pipe(map(bookingPaymentStatusFromDto))
    }

    public getAll(
        page: number,
        pageSize: number,
        searchCriteria: MyBookingsListFilter
    ): Observable<PagedEntities<MyBookingsListItem>> {
        return this.http
            .get<PagedEntities<MyBookingsListItemDto>>(this.basePath, {
                params: {
                    ...extractPrimitiveNonNullProperties(myBookingsListFilterToDto(searchCriteria)),
                    page: page,
                    pageSize: pageSize
                }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, myBookingsListItemFromDto)))
    }

    public insert(booking: CreateBooking): Observable<Booking> {
        return this.http
            .put<BookingDto>(this.basePath, createBookingToDto(booking))
            .pipe(map((dto: BookingDto) => bookingFromDto(dto)))
    }

    public stopCollaboration(bookingId: number): Observable<Booking> {
        return this.http.delete<BookingDto>(`${this.basePath}/${bookingId}`).pipe(map(bookingFromDto))
    }

    public update(bookingId: number, booking: UpdateBooking): Observable<Booking> {
        return this.http
            .post<BookingDto>(`${this.basePath}/${bookingId}`, updateBookingToDto(booking))
            .pipe(map((dto: BookingDto) => bookingFromDto(dto)))
    }
}
