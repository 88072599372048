import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, NavigationExtras, Router, UrlTree } from '@angular/router'
import { map, Observable } from 'rxjs'
import { LinkxAuthFacade } from '../linkx-auth.facade'

export const employeeSearchDetailGuard = (snapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
    const router: Router = inject(Router)
    return inject(LinkxAuthFacade)
        .loadUserProfile()
        .pipe(
            map(Boolean),
            map((isRegistered) => {
                if (isRegistered) {
                    return true
                }

                const navigationExtras: NavigationExtras = {
                    queryParams: {
                        redirectTarget: JSON.stringify({
                            entity: 'EmployeeSearchResult',
                            id: snapshot.params['employeeId'],
                            queryParams: snapshot.queryParams
                        })
                    }
                }
                sessionStorage.setItem('redirectTarget', router.url)
                return router.createUrlTree(['/employer/register'], navigationExtras)
            })
        )
}
