import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core'
import { notNull } from '@ppwcode/js-ts-oddsandends/lib/conditional-assert'
import { map, Observable, Subscription, timer } from 'rxjs'
import { CognitoUserInfo } from '../cognito-user-info'
import { LinkxAuthFacade } from '../linkx-auth.facade'
import { mixinRelativeNavigation } from '@linkx/shared-utils'
@Component({
    selector: 'linkx-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent extends mixinRelativeNavigation() implements OnDestroy {
    private linkxAuth: LinkxAuthFacade = inject(LinkxAuthFacade)
    public timer?: Subscription = undefined
    public currentUser$: Observable<CognitoUserInfo> = this.linkxAuth.getCurrentUser().pipe(map(notNull))

    public constructor() {
        super()
        const redirectTarget = sessionStorage.getItem('redirectTarget')
        if (redirectTarget !== null && redirectTarget !== undefined && redirectTarget !== '') {
            //Wait 2 sec and redirect to url in session storage.
            this.timer = timer(5000).subscribe(() => {
                sessionStorage.removeItem('redirectTarget')

                this.relativeNavigation([redirectTarget])
            })
        }
    }

    public ngOnDestroy(): void {
        this.timer?.unsubscribe()
    }
}
