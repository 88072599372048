import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import {
    APP_CONFIG,
    AppConfig,
    extractPrimitiveNonNullProperties,
    PagedEntities,
    pagedEntitiesFromDto
} from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import { Employer, EmployerDto, employerFromDto, employerToDto } from './employer.model'
import { EmployerListFilter, employerListFilterToDto } from './employer-list-filter/employer-list-filter.model'
import {
    MyTransactionlogsListFilter,
    MyTransactionlogsListFilterToDto
} from '../transactionlog/my-transactionlogs-filter/my-transactionlogs-list-filter.model'
import { Transactionlog, TransactionlogDto, transactionLogFromDto } from '../transactionlog/transactionlog.model'
@Injectable({
    providedIn: 'root'
})
export class EmployerService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)
    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/employers`
    }

    public getById(id: number): Observable<Employer> {
        return this.http.get<EmployerDto>(`${this.basePath}/${id}`).pipe(map(employerFromDto))
    }

    public getAll(
        page: number,
        pageSize: number,
        searchCriteria: EmployerListFilter
    ): Observable<PagedEntities<Employer>> {
        return this.http
            .get<PagedEntities<EmployerDto>>(this.basePath, {
                params: {
                    ...employerListFilterToDto(searchCriteria),
                    page: page,
                    pageSize: pageSize
                }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, employerFromDto)))
    }

    public getQrCode(id: number): Observable<string> {
        const url = `${this.basePath}/My/Qr?id=${id}`
        return this.http.get(url, { responseType: 'text' })
    }

    public insert(employer: Employer): Observable<Employer> {
        return this.http.put<Employer>(this.basePath, employerToDto(employer)).pipe(map(employerFromDto))
    }

    public update(employer: Employer): Observable<Employer> {
        return this.http
            .post<Employer>(`${this.basePath}/${employer.id}`, employerToDto(employer))
            .pipe(map(employerFromDto))
    }

    public getTransactionlogs(
        employerId: number,
        page: number,
        pageSize: number,
        searchCriteria: MyTransactionlogsListFilter
    ): Observable<PagedEntities<Transactionlog>> {
        return this.http
            .get<PagedEntities<TransactionlogDto>>(`${this.basePath}/${employerId}/transactionlogs`, {
                params: {
                    ...extractPrimitiveNonNullProperties(MyTransactionlogsListFilterToDto(searchCriteria)),
                    page: page,
                    pageSize: pageSize
                }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, transactionLogFromDto)))
    }
}
