import { DateTime } from 'luxon'
import { Qualification, QualificationDto, qualificationFromDto } from '../../qualification/qualification.model'
import { BookingStatus } from '../booking-status.enum'

export interface EmployeeBookingDto {
    name: string
    startDate: string
    numberOfMinutes: number
    remainingNumberOfMinutes: number
    status: BookingStatus
    employeeId: number
    employerId: number
    description: string
    qualification: QualificationDto
    id: number
}

export interface EmployeeBooking {
    name: string
    startDate: DateTime
    numberOfMinutes: number
    remainingNumberOfMinutes: number
    status: BookingStatus
    employeeId: number
    employerId: number
    description: string
    qualification: Qualification
    id: number
}

export const employeeBookingFromDto = (dto: EmployeeBookingDto): EmployeeBooking => {
    return {
        ...dto,
        numberOfMinutes: dto.numberOfMinutes,
        remainingNumberOfMinutes: dto.remainingNumberOfMinutes,
        startDate: DateTime.fromISO(dto.startDate),
        qualification: qualificationFromDto(dto.qualification)
    }
}
