import { Qualification, qualificationFromDto, qualificationToDto } from '../qualification/qualification.model'

export interface WorkExperience {
    id: number | null
    qualification: Qualification
    yearsOfExperience: number
    rate: number
}

export interface WorkExperienceDto {
    id: number | null
    qualification: Qualification
    yearsOfExperience: number
    rate: number
}

export const workExperienceToDto = (workExperience: WorkExperience): WorkExperienceDto => {
    return {
        ...workExperience,
        qualification: qualificationToDto(workExperience.qualification)
    }
}

export const workExperienceFromDto = (workExperienceDto: WorkExperienceDto): WorkExperience => {
    return {
        ...workExperienceDto,
        qualification: qualificationFromDto(workExperienceDto.qualification)
    }
}
