import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    expectAsyncResultHttpError
} from '@linkx/shared-utils'
import { Select, Store } from '@ngxs/store'
import { CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import { ICredentials } from 'aws-amplify/lib/Common/types/types'
import { from, map, Observable, of, switchMap, tap } from 'rxjs'
import { UserAuthenticated, UserSignedOut } from './+state/auth.action'
import { AuthSelector } from './+state/auth.selector'
import { CognitoUserInfo } from './cognito-user-info'
import { CreateOrUpdateUserEmployer } from './create-or-update-user-employer'
import { LinkxAuthService } from './linkx-auth.service'
import { LinkxUserInfo } from './linkx-user-info'

@Injectable({
    providedIn: 'root'
})
export class LinkxAuthFacade {
    @Select(AuthSelector.authenticatedUser) private authenticatedUser$!: Observable<LinkxUserInfo | null>

    private linkxAuthService: LinkxAuthService = inject(LinkxAuthService)
    private store: Store = inject(Store)
    private router: Router = inject(Router)

    private readonly afterAuthenticationUrlKey = 'after-authentication-url'
    private _routeToken: string | null = null
    public get routeToken(): string | null {
        return this._routeToken
    }

    public signInFacebook(): Promise<ICredentials> {
        sessionStorage.setItem(this.afterAuthenticationUrlKey, this.router.url)
        return this.linkxAuthService.signInFacebook()
        // No authentication dispatch here because this triggers a redirect.
        // The dispatch of the authenticated user is executed in a guard.
    }

    public signInGoogle(): Promise<ICredentials> {
        sessionStorage.setItem(this.afterAuthenticationUrlKey, this.router.url)
        return this.linkxAuthService.signInGoogle()
        // No authentication dispatch here because this triggers a redirect.
        // The dispatch of the authenticated user is executed in a guard.
    }

    public signOut(): Promise<void> {
        return this.linkxAuthService.signOut().then(() => {
            this.store.dispatch(new UserSignedOut())
        })
    }

    public signInBearerToken(token: string | null): void {
        this._routeToken = token
    }

    public getActiveAccessToken(): Observable<CognitoAccessToken> {
        return this.linkxAuthService.getAccessToken()
    }

    public getCurrentUser(): Observable<CognitoUserInfo | null> {
        return from(this.linkxAuthService.currentUser())
    }

    public getActiveRefreshToken(): Observable<CognitoRefreshToken> {
        return this.linkxAuthService.getRefreshToken()
    }

    public isAuthenticated(): Observable<boolean> {
        return from(this.linkxAuthService.currentUser()).pipe(map(Boolean))
    }

    public saveUserEmployer(
        createOrUpdateUserEmployer: CreateOrUpdateUserEmployer
    ): Observable<AsyncResult<LinkxUserInfo | null>> {
        return this.linkxAuthService.saveUserEmployer(createOrUpdateUserEmployer).pipe(
            switchMap((userInfo: LinkxUserInfo) => {
                return this.linkxAuthService.refreshAccessToken().pipe(map(() => userInfo))
            }),
            tap((userInfo) => this.store.dispatch(new UserAuthenticated(userInfo))),
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public loadUserProfile(): Observable<LinkxUserInfo | null> {
        return this.getCurrentUser().pipe(
            switchMap((userOrNull) => {
                if (userOrNull === null) {
                    return of(null)
                }

                return this.linkxAuthService
                    .getUser(userOrNull.sub)
                    .pipe(tap((userInfo) => (userInfo ? this.store.dispatch(new UserAuthenticated(userInfo)) : null)))
            })
        )
    }

    public getUserProfile(): Observable<LinkxUserInfo | null> {
        return this.authenticatedUser$.pipe(
            switchMap((linkxUserInfoOrNull) => {
                if (linkxUserInfoOrNull === null) {
                    return this.loadUserProfile()
                }

                return of(linkxUserInfoOrNull)
            }),
            switchMap(() => this.authenticatedUser$)
        )
    }

    public fullAuthRefresh(): Observable<LinkxUserInfo | null> {
        return this.linkxAuthService.refreshAccessToken().pipe(
            tap(() => this.store.dispatch(new UserAuthenticated(null))),
            switchMap(() => this.getUserProfile())
        )
    }

    public redirectAfterLogin(): void {
        const afterAuthenticationUrl: string | null = sessionStorage.getItem(this.afterAuthenticationUrlKey)
        if (afterAuthenticationUrl) {
            sessionStorage.removeItem(this.afterAuthenticationUrlKey)
            this.router.navigateByUrl(afterAuthenticationUrl)
        }
    }
}
