import { inject, Injectable } from '@angular/core'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    expectAsyncResultHttpError,
    expectPagedListHttpError,
    expectPagedListHttpSuccess,
    isPagedListSameQuery,
    PagedList
} from '@linkx/shared-utils'
import { Select, Store } from '@ngxs/store'
import { map, Observable, switchMap, take } from 'rxjs'
import {
    BookingsLoaded,
    ClearBookings,
    EmployeeBookingsLoaded,
    EmployerBookingsLoaded,
    StateCleared
} from '../+state/booking.action'
import { BookingSelector } from '../+state/booking.selector'
import { BookingItem } from '../booking/booking-item.model'
import { BookingListFilter } from '../booking/booking-list-filter.model'
import { Booking } from '../booking/booking.model'
import { EmployeeBookingFilter } from '../booking/employee-booking-filter.model'
import { EmployeeBooking } from '../booking/employee-booking.model'
import { EmployerBookingFilter } from '../booking/employer-booking-filter.model'
import { EmployerBooking } from '../booking/employer-booking.model'
import { UpdateBookingAsAdmin } from '../booking/update-booking-as-admin.model'
import { BackofficeBookingService } from '../services/backoffice-booking.service'

@Injectable({ providedIn: 'root' })
export class BackofficeBookingFacade {
    // Stream containing the list of BookingListItems in the state.
    @Select(BookingSelector.bookings)
    private readonly bookings$!: Observable<PagedList<BookingItem, BookingListFilter>>
    // Stream containig the list of bookings for an employer.
    @Select(BookingSelector.employerBookings)
    private readonly employerBookings$!: Observable<PagedList<EmployerBooking, EmployerBookingFilter>>
    // Stream containig the list of bookings for an employee.
    @Select(BookingSelector.employeeBookings)
    private readonly employeeBookings$!: Observable<PagedList<EmployeeBooking, EmployeeBookingFilter>>

    private store: Store = inject(Store)
    private backofficeBookingService: BackofficeBookingService = inject(BackofficeBookingService)

    public getBookings(
        pageIndex: number,
        pageSize: number,
        searchCriteria: BookingListFilter
    ): Observable<PagedList<BookingItem, BookingListFilter>> {
        const dispatchLoaded = (pl: PagedList<BookingItem, BookingListFilter>): Observable<unknown> =>
            this.store.dispatch(new BookingsLoaded(pl))

        return this.bookings$.pipe(
            take(1),
            switchMap((bookingsList: PagedList<BookingItem, BookingListFilter>) => {
                if (isPagedListSameQuery(bookingsList, pageIndex, searchCriteria)) {
                    return this.bookings$
                }

                return this.backofficeBookingService.getAll(pageIndex, pageSize, searchCriteria).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded),
                    switchMap(() => this.bookings$)
                )
            })
        )
    }

    public getBookingsByEmployer(
        employerId: number,
        pageIndex: number,
        pageSize: number,
        filter: EmployerBookingFilter
    ): Observable<PagedList<EmployerBooking, EmployerBookingFilter>> {
        const dispatchLoaded = (pl: PagedList<EmployerBooking, EmployerBookingFilter>): Observable<unknown> =>
            this.store.dispatch(new EmployerBookingsLoaded(pl))

        return this.employerBookings$.pipe(
            take(1),
            switchMap((bookingsList: PagedList<EmployerBooking, EmployerBookingFilter>) => {
                if (isPagedListSameQuery(bookingsList, pageIndex, filter)) {
                    return this.employerBookings$
                }

                return this.backofficeBookingService.getAllByEmployerId(employerId, pageIndex, pageSize, filter).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded),
                    switchMap(() => this.employerBookings$)
                )
            })
        )
    }

    public getBookingsByEmployee(
        employeeId: number,
        pageIndex: number,
        pageSize: number,
        filter: EmployeeBookingFilter
    ): Observable<PagedList<EmployeeBooking, EmployeeBookingFilter>> {
        const dispatchLoaded = (pl: PagedList<EmployeeBooking, EmployeeBookingFilter>): Observable<unknown> =>
            this.store.dispatch(new EmployeeBookingsLoaded(pl))

        return this.employeeBookings$.pipe(
            take(1),
            switchMap((bookingsList: PagedList<EmployeeBooking, EmployeeBookingFilter>) => {
                if (isPagedListSameQuery(bookingsList, pageIndex, filter)) {
                    return this.employeeBookings$
                }

                return this.backofficeBookingService.getAllByEmployeeId(employeeId, pageIndex, pageSize, filter).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded),
                    switchMap(() => this.employeeBookings$)
                )
            })
        )
    }

    public getBookingById(bookingId: number): Observable<AsyncResult<Booking | null>> {
        return this.backofficeBookingService.getById(bookingId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public updateBookingAsAdmin(booking: UpdateBookingAsAdmin): Observable<AsyncResult<Booking | null>> {
        return this.backofficeBookingService.update(booking).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public approveBooking(bookingId: number): Observable<AsyncResult<Booking | null>> {
        return this.backofficeBookingService.approve(bookingId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public changeEmployee(bookingId: number, employeeId: number): Observable<AsyncResult<Booking | null>> {
        return this.backofficeBookingService.changeEmployee(bookingId, employeeId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public clearBookingsList(): void {
        this.store.dispatch(new ClearBookings())
    }

    public clearState(): void {
        this.store.dispatch(new StateCleared())
    }
}
