import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator'
import { PagedList } from '@linkx/shared-utils'

@Component({
    selector: 'linkx-pagination-bar',
    standalone: true,
    imports: [CommonModule, MatPaginatorModule],
    templateUrl: './pagination-bar.component.html'
})
export class PaginationBarComponent {
    @Input() public pagedList!: PagedList<unknown, unknown>
    @Input() public hidePageSize = true
    @Output() public page = new EventEmitter<PageEvent>()

    public handlePageEvent(e: PageEvent): void {
        this.page.emit(e)
    }
}
