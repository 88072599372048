import { PaymentStatus } from '../../payment/payment-status.enum'

export interface BookingPaymentStatusDto {
    bookingId: number
    paymentStatus: PaymentStatus
}

export interface BookingPaymentStatus {
    bookingId: number
    paymentStatus: PaymentStatus
}

export const bookingPaymentStatusFromDto = (dto: BookingPaymentStatusDto): BookingPaymentStatus => {
    return { ...dto }
}
