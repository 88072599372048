import { DateTime } from 'luxon'
import { Qualification, QualificationDto, qualificationFromDto } from '../../qualification/qualification.model'
import { BookingStatus } from '../booking-status.enum'

export interface BookingItem {
    companyName: string
    description: string
    employeeName: string
    employerId: number
    id: number
    numberOfMinutes: number
    qualification: Qualification
    remainingNumberOfMinutes: number
    startDate: DateTime
    status: BookingStatus
}

export interface BookingItemDto {
    companyName: string
    description: string
    employeeName: string
    employerId: number
    id: number
    numberOfMinutes: number
    qualification: QualificationDto
    remainingNumberOfMinutes: number
    startDate: string
    status: string
}

export const bookingItemFromDto = (dto: BookingItemDto): BookingItem => {
    return {
        ...dto,
        description: dto.description ?? '',
        numberOfMinutes: dto.numberOfMinutes,
        qualification: qualificationFromDto(dto.qualification),
        remainingNumberOfMinutes: dto.remainingNumberOfMinutes,
        startDate: DateTime.fromISO(dto.startDate),
        status: dto.status as BookingStatus
    }
}
