import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular'
import { AwsCognitoAppConfig } from '@linkx/shared-utils'
import { TranslateModule } from '@ngx-translate/core'
import { NgxsModule } from '@ngxs/store'
import { AuthState } from './+state/auth.state'
import { AuthInterceptor } from './auth-interceptor'
import { configureAmplify, configureAmplifyTranslations } from './configure'
import { SignInComponent } from './sign-in/sign-in.component'
import { WelcomeComponent } from './welcome/welcome.component'

export interface SharedAuthModuleConfig {
    awsCognito: AwsCognitoAppConfig
    defaultLanguage: string
}

@NgModule({
    declarations: [SignInComponent, WelcomeComponent],
    imports: [CommonModule, AmplifyAuthenticatorModule, TranslateModule, NgxsModule.forFeature([AuthState])],
    exports: [AmplifyAuthenticatorModule, SignInComponent]
})
export class SharedAuthModule {
    public static forRoot({
        awsCognito,
        defaultLanguage
    }: SharedAuthModuleConfig): ModuleWithProviders<SharedAuthModule> {
        configureAmplify(awsCognito)
        configureAmplifyTranslations(defaultLanguage)

        return {
            ngModule: SharedAuthModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true
                }
            ]
        }
    }
}
