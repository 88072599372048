import { inject, Injectable } from '@angular/core'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    expectAsyncResultHttpError,
    expectPagedListHttpError,
    expectPagedListHttpSuccess,
    isPagedListSameQuery,
    PagedList
} from '@linkx/shared-utils'
import { Select, Store } from '@ngxs/store'
import { map, Observable, switchMap, take } from 'rxjs'
import { ClearMyBookings, MyBookingsLoaded, StateCleared } from '../+state/booking.action'
import { BookingSelector } from '../+state/booking.selector'
import { BookingPaymentStatus } from '../booking/booking-payment-status'
import { Booking } from '../booking/booking.model'
import { CreateBooking } from '../booking/create-booking.model'
import { UpdateBooking } from '../booking/update-booking.model'
import { MyBookingsListItem } from '../mybooking/my-bookings-list-item.model'
import { FrontofficeBookingService } from '../services/frontoffice-booking.service'
import { MyBookingsListFilter } from '../mybooking-filter/my-bookings-list-filter.model'
import { BookingListFilter } from '../booking/booking-list-filter.model'

@Injectable({ providedIn: 'root' })
export class FrontofficeBookingFacade {
    // Stream containing the list of MyBookingsListItems in the state.
    @Select(BookingSelector.myBookings)
    private readonly myBookings$!: Observable<PagedList<MyBookingsListItem, MyBookingsListFilter>>

    private frontofficeBookingService: FrontofficeBookingService = inject(FrontofficeBookingService)
    private store: Store = inject(Store)

    public getBookings(
        pageIndex: number,
        pageSize: number,
        searchCriteria: MyBookingsListFilter
    ): Observable<PagedList<MyBookingsListItem, MyBookingsListFilter>> {
        const dispatchLoaded = (pl: PagedList<MyBookingsListItem, MyBookingsListFilter>): Observable<unknown> =>
            this.store.dispatch(new MyBookingsLoaded(pl))

        return this.myBookings$.pipe(
            take(1),
            switchMap((myBookings: PagedList<MyBookingsListItem, BookingListFilter>) => {
                if (isPagedListSameQuery(myBookings, pageIndex, searchCriteria)) {
                    return this.myBookings$
                }

                return this.frontofficeBookingService.getAll(pageIndex, pageSize, searchCriteria).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded),
                    switchMap(() => this.myBookings$)
                )
            })
        )
    }

    public getBookingById(bookingId: number): Observable<AsyncResult<Booking | null, null>> {
        return this.frontofficeBookingService.getById(bookingId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public getBookingPaymentStatus(bookingId: number): Observable<AsyncResult<BookingPaymentStatus | null>> {
        return this.frontofficeBookingService.getPaymentStatus(bookingId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public insertBooking(booking: CreateBooking): Observable<AsyncResult<Booking | null>> {
        return this.frontofficeBookingService.insert(booking).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public stopCollaborationBooking(bookingId: number): Observable<AsyncResult<Booking | null>> {
        return this.frontofficeBookingService.stopCollaboration(bookingId).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public updateBooking(bookingId: number, booking: UpdateBooking): Observable<AsyncResult<Booking | null>> {
        return this.frontofficeBookingService.update(bookingId, booking).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public clearBookingsList(): void {
        this.store.dispatch(new ClearMyBookings())
    }

    public clearState(): void {
        this.store.dispatch(new StateCleared())
    }
}
