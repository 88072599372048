import { Component, inject, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { LinkxAuthFacade, LinkxUserInfo, userIsEmployee, userIsEmployer } from '@linkx/shared-auth'
import { mixinHandleSubscriptions } from '@linkx/shared-utils'
import { NavigationItem } from '@linkx/shared-wireframe'
import { map, Observable } from 'rxjs'

@Component({
    selector: 'linkx-root',
    templateUrl: './app.component.html'
})
export class AppComponent extends mixinHandleSubscriptions() implements OnInit {
    // The app component is not using the amplify-authenticator because the sign in procedure is "delayed".
    // A user can sign in using the toolbar, or when he enters a guarded segment of the application (search
    // result details).

    private auth: LinkxAuthFacade = inject(LinkxAuthFacade)
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute)

    public currentUserProfile$: Observable<LinkxUserInfo | null> = this.auth.getUserProfile()
    public routeToken$: Observable<string | null> = this.activatedRoute.queryParamMap.pipe(
        map((params) => params.get('token'))
    )

    // It is basically impossible to send a token to the application from the mobile app.
    // What keeps us back most is the lack of opportunity to store it in the aws-amplify library.
    // That's why we only show the route with the token instead of the entire wireframe, just to prevent
    // the user from trying to access other parts of the application with the same token.
    public isTokenRoute$: Observable<boolean> = this.routeToken$.pipe(map(Boolean))

    public title = 'frontoffice'
    public navigationItems$: Observable<Array<NavigationItem>> = this.currentUserProfile$.pipe(
        map((currentUserProfile) => {
            const navigationItems: Array<NavigationItem> = [
                { icon: 'search', label: 'sidenav.search', fullRouterPath: 'employee', isEnabled: true }
            ]

            if (userIsEmployee(currentUserProfile)) {
                navigationItems.push({
                    icon: 'person',
                    label: 'sidenav.my-profile',
                    fullRouterPath: 'employee/my-profile'
                })
            } else if (userIsEmployer(currentUserProfile)) {
                navigationItems.push(
                    {
                        icon: 'person',
                        label: 'sidenav.my-profile',
                        fullRouterPath: 'employer/my-profile'
                    },
                    {
                        icon: 'qr_code',
                        label: 'sidenav.my-qr',
                        fullRouterPath: 'employer/my-qr'
                    },
                    {
                        icon: 'calendar_today',
                        label: 'sidenav.my-requests',
                        fullRouterPath: 'my-bookings'
                    }
                )
            }

            return navigationItems
        })
    )

    public ngOnInit(): void {
        this.stopOnDestroy(this.routeToken$).subscribe((token) => this.auth.signInBearerToken(token))
        this.auth.redirectAfterLogin()
    }
}
