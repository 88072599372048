import { inject, OnDestroy, Pipe, PipeTransform } from '@angular/core'
import { CountryFacade } from '@linkx/shared-data-access'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'

@Pipe({
    name: 'translateCountry',
    standalone: true
})
export class TranslateCountryPipe implements PipeTransform, OnDestroy {
    private countryFacade: CountryFacade = inject(CountryFacade)
    private translateService: TranslateService = inject(TranslateService)
    private translations!: Record<string, string>
    private languageSubscription: Subscription = this.translateService.onLangChange.asObservable().subscribe(() => {
        this.translations = this.countryFacade.getCountryTranslations(this.translateService.currentLang)
    })

    public ngOnDestroy(): void {
        this.languageSubscription.unsubscribe()
    }

    public transform(value: string): string | null {
        this.translations ??= this.countryFacade.getCountryTranslations(this.translateService.currentLang)
        return this.translations?.[value] ?? value
    }
}
