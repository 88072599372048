import { Language, LanguageDto, languageFromDto } from '../../language/language.model'
import { WorkExperience, WorkExperienceDto, workExperienceFromDto } from '../../workexperience/workexperience.model'
import { employeeAgeCalculator } from '../employee-age-calculator'
import { Certificate, CertificateDto, certificateFromDto } from '../../certificate/certificate.model'

export interface EmployeeSearchDetail {
    id: number
    lastName: string
    firstName: string
    age: number
    workExperiences: Array<WorkExperience>
    languages: Array<Language>
    certificates: Array<Certificate>
}

export interface EmployeeSearchDetailDto {
    id: number
    lastName: string
    firstName: string
    birthDate: string
    workExperiences: Array<WorkExperienceDto>
    languages: Array<LanguageDto>
    certificates: Array<CertificateDto>
}
export const employeeSearchDetailFromDto = (employeeSearchDetailDto: EmployeeSearchDetailDto): EmployeeSearchDetail => {
    return {
        ...employeeSearchDetailDto,
        age: employeeAgeCalculator(employeeSearchDetailDto.birthDate),
        certificates: employeeSearchDetailDto.certificates.map(certificateFromDto),
        languages: employeeSearchDetailDto.languages.map(languageFromDto),
        workExperiences: employeeSearchDetailDto.workExperiences.map(workExperienceFromDto)
    }
}
