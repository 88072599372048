import { Component, Input } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { SafeHtmlPipe } from '@linkx/shared-pipes-ui'

@Component({
    selector: 'linkx-my-qr',
    standalone: true,
    templateUrl: './my-qr.component.html',
    imports: [TranslateModule, SafeHtmlPipe],
    styleUrls: ['./my-qr.component.scss']
})
export class MyQrComponent {
    @Input() public qrCodeString!: string
}
