<div
    class="wrapper"
    [matTooltip]="'progress-bar.left' | translate: { hours: remainingNumberOfMinutes, maxhours: numberOfMinutes }">
    <div class="remainingNumberOfMinutes" *ngIf="includeNumbers">
        {{ remainingNumberOfMinutes }}
    </div>
    <div class="progress-bar-background" [style.background-color]="backgroundColor">
        <div class="progress-bar" [style.width.%]="width">&nbsp;</div>
    </div>
    <div class="numberOfMinutes" *ngIf="includeNumbers">
        {{ numberOfMinutes }}
    </div>
</div>
