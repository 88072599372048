import { DateTime } from 'luxon'

export interface UpdateBooking {
    description: string
    employeeId: number
    locationRemarks: string
    numberOfMinutes: number
    startDate: DateTime
}

export interface UpdateBookingDto {
    description: string
    employeeId: number
    locationRemarks: string
    numberOfMinutes: number
    startDate: string
}

export const updateBookingToDto = (updateBooking: UpdateBooking): UpdateBookingDto => {
    return {
        ...updateBooking,
        numberOfMinutes: updateBooking.numberOfMinutes,
        startDate: updateBooking.startDate.toUTC().toISO()
    }
}

export const updateBookingFromDto = (updateBookingDto: UpdateBookingDto): UpdateBooking => {
    return {
        ...updateBookingDto,
        numberOfMinutes: updateBookingDto.numberOfMinutes,
        startDate: DateTime.fromISO(updateBookingDto.startDate)
    }
}
