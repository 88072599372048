export interface Certificate {
    id: number | null
    certificateName: string
    obtained: boolean
}

export interface CertificateDto {
    id: number | null
    certificateName: string
    obtained: boolean
}

export const certificateToDto = (certificate: Certificate): CertificateDto => {
    return { ...certificate }
}

export const certificateFromDto = (certificateDto: CertificateDto): Certificate => {
    return { ...certificateDto }
}
