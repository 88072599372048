import { inject, InjectionToken, Pipe, PipeTransform } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { DateTime } from 'luxon'

export interface LuxonDatePipeOptions {
    dateLabel: 'dd/MM/yyyy'
    dateTimeLabel: 'dd/MM/yyyy HH:mm'
}

export interface LuxonDatePipeFormatOptions {
    dateOnly: boolean
}

export const LUXON_DATE_PIPE_OPTIONS = new InjectionToken<LuxonDatePipeOptions>('luxon-date-pipe', {
    providedIn: 'root',
    factory: () => ({
        dateLabel: 'dd/MM/yyyy',
        dateTimeLabel: 'dd/MM/yyyy HH:mm'
    })
})

@Pipe({
    name: 'luxonDate',
    standalone: true
})
export class LuxonDatePipe implements PipeTransform {
    private dateAdapter: DateAdapter<DateTime> = inject(DateAdapter)
    private options: LuxonDatePipeOptions = inject(LUXON_DATE_PIPE_OPTIONS)

    public transform(value: DateTime | null, formatOptions?: LuxonDatePipeFormatOptions): string {
        if (value === null) {
            return ''
        }
        return this.dateAdapter.format(
            value,
            formatOptions?.dateOnly ? this.options.dateLabel : this.options.dateTimeLabel
        )
    }
}
