export enum BookingStatus {
    NONE = 'NONE',
    INITIATED = 'INITIATED',
    CONFIRMED_BY_EMPLOYEE = 'CONFIRMED_BY_EMPLOYEE',
    PAYMENT_INITIATED = 'PAYMENT_INITIATED',
    PAID = 'PAID',
    CLOSED = 'CLOSED',
    INTERRUPTED = 'INTERRUPTED',
    CANCELED = 'CANCELED'
}
