import { AppConfig } from '@linkx/shared-utils'

export const environment: AppConfig = {
    isProduction: true,
    api: {
        url: '/api',
        version: 'v1'
    },
    awsCognito: {
        domain: 'linkx-int.auth.eu-west-1.amazoncognito.com',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_tCT7145v7',
        userPoolWebClientId: '3m2f99c7iemjqe6t54572835eu'
    }
}
