import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
    ViewChild
} from '@angular/core'
import { MatDrawer } from '@angular/material/sidenav'
import { Subject, takeUntil } from 'rxjs'
import { NavigationItem } from '../navigation-item/navigation-item.model'

@Component({
    selector: 'linkx-app-wireframe',
    templateUrl: './app-wireframe.component.html',
    styleUrls: ['./app-wireframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppWireframeComponent implements AfterViewInit, OnDestroy {
    public observer: BreakpointObserver = inject(BreakpointObserver)
    private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef)
    private destroy$: Subject<void> = new Subject<void>()

    @Input() public navigationItems: Array<NavigationItem> | null = []
    @ViewChild(MatDrawer) public matDrawer!: MatDrawer

    public get isSmallDevice(): boolean {
        return this.observer.isMatched([Breakpoints.Small])
    }

    public get isXSmallDevice(): boolean {
        return this.observer.isMatched([Breakpoints.XSmall])
    }

    public get isLargerDevice(): boolean {
        return this.observer.isMatched([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
    }

    public get isSidenavOpen(): boolean {
        return this.matDrawer?.opened ?? (!this.isSmallDevice && !this.isXSmallDevice)
    }

    public trackSidenavVisibility(): void {
        this.observer
            .observe([Breakpoints.XSmall, Breakpoints.Small])
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                if (result.matches && this.matDrawer.opened) {
                    this.matDrawer.close()
                } else if (!result.matches && !this.matDrawer.opened) {
                    this.matDrawer.open()
                }
                this.cdRef.markForCheck()
            })
    }

    public ngAfterViewInit(): void {
        this.trackSidenavVisibility()
    }

    public ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }

    public async toggleSidebar(): Promise<void> {
        await this.matDrawer.toggle()
    }

    public async closeSidebar(): Promise<void> {
        await this.matDrawer.close()
    }
}
