import { inject } from '@angular/core'
import { map, Observable } from 'rxjs'
import { LinkxAuthFacade } from '../linkx-auth.facade'
import { LinkxUserInfo } from '../linkx-user-info'

export const userIsEmployee = (userProfileOrNull: LinkxUserInfo | null): boolean => {
    if (userProfileOrNull === null || userProfileOrNull.cognito.userInfo === null) {
        return false
    }

    const session = userProfileOrNull.cognito.userInfo.userSessionPayload
    return !!userProfileOrNull.employeeId && sessionHasGroup(session, 'employee')
}

export const userIsEmployer = (userProfileOrNull: LinkxUserInfo | null): boolean => {
    if (userProfileOrNull === null || userProfileOrNull.cognito.userInfo === null) {
        return false
    }

    const session = userProfileOrNull.cognito.userInfo.userSessionPayload
    return !!userProfileOrNull.employerId && sessionHasGroup(session, 'employer')
}

export const userIsAdmin = (userProfileOrNull: LinkxUserInfo | null): boolean => {
    if (userProfileOrNull === null || userProfileOrNull.cognito.userInfo === null) {
        return false
    }

    const session = userProfileOrNull.cognito.userInfo.userSessionPayload
    return (
        (userProfileOrNull.userLevel === 'ADMIN' && sessionHasGroup(session, 'admin')) ||
        (userProfileOrNull.userLevel === 'SUPER_ADMIN' && sessionHasGroup(session, 'super_admin'))
    )
}

export const sessionHasGroup = (session: Record<string, unknown>, expectedGroup: string): boolean => {
    return (session['cognito:groups'] as Array<string>).indexOf(expectedGroup) > -1
}

export const linkxProfileGuard = (): Observable<boolean> => inject(LinkxAuthFacade).loadUserProfile().pipe(map(Boolean))

export const linkxEmployerGuard = (): Observable<boolean> =>
    inject(LinkxAuthFacade).loadUserProfile().pipe(map(userIsEmployer))

export const linkxEmployeeGuard = (): Observable<boolean> =>
    inject(LinkxAuthFacade).loadUserProfile().pipe(map(userIsEmployee))

export const linkxAdminGuard = (): Observable<boolean> =>
    inject(LinkxAuthFacade).loadUserProfile().pipe(map(userIsAdmin))
