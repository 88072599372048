import { DateTime, Duration } from 'luxon'

export const minutesBetween = (start: DateTime, end: DateTime | null): number => {
    if (end !== null) {
        const duration = Duration.fromMillis(end.valueOf() - start.valueOf())
        return duration.as('minutes')
    }

    return 0
}
