import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'
import { PrimaryButtonComponent } from '../primary-button/primary-button.component'
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component'

/* NgxTranslate expects any for params. tslint should stop complaining */
export interface IConfirmationDialogData {
    titleKey: string
    bodyKey: string

    /* eslint-disable @typescript-eslint/no-explicit-any */
    bodyParams: any
    titleParams: any
    /* eslint-enable */

    confirmationKey: string
    cancelKey: string
}

@Component({
    standalone: true,
    selector: 'linkx-dialog',
    templateUrl: './confirmation-dialog.component.html',
    imports: [MatDialogModule, TranslateModule, MatButtonModule, SecondaryButtonComponent, PrimaryButtonComponent],
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    public constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData) {}
}
