import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ButtonLoadingDirective } from '../button-loading/button-loading.directive'

@Component({
    selector: 'linkx-primary-button',
    templateUrl: 'primary-button.component.html',
    styleUrls: ['primary-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, CommonModule, MatButtonModule, TranslateModule, ButtonLoadingDirective],
    standalone: true
})
export class PrimaryButtonComponent {
    @Input() public fontSet = 'fa-solid'
    @Input() public fontIcon: string | null = null
    @Input() public label!: string
    @Input() public disabled: boolean | null = false
    @Input() public loading: boolean | null = false

    public constructor(elementRef: ElementRef) {
        // Because this is a button-like component, developers will attach click event handlers using
        // the (click) syntax. This will ignore the [disabled] attribute because the prevention of the
        // JavaScript click event is only using the [disabled] attribute is only possible for elements
        // that are natively clickable (like buttons). The following block of code will ensure that
        // when a (click) handler is attached to this component, it will take the disabled attribute into
        // account.
        elementRef.nativeElement.addEventListener('click', (event: MouseEvent) => {
            if (this.disabled) {
                event.stopImmediatePropagation()
            }
        })
    }
}
