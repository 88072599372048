import { inject, Injectable } from '@angular/core'
import {
    AsyncResult,
    createSuccessAsyncResult,
    DEFAULT_HTTP_ERROR_CODES,
    expectAsyncResultHttpError,
    expectPagedListHttpError,
    expectPagedListHttpSuccess,
    isPagedListSameQuery,
    PagedList
} from '@linkx/shared-utils'
import { Select, Store } from '@ngxs/store'
import { map, Observable, switchMap, take, tap } from 'rxjs'
import {
    ClearEmployers,
    EmployersLoaded,
    EmployerTransactionlogsLoaded,
    EmployerUpdated
} from './+state/employer.action'
import { EmployerSelector } from './+state/employer.selector'
import { Employer } from './employer.model'
import { EmployerService } from './employer.service'
import { EmployerListFilter } from './employer-list-filter/employer-list-filter.model'
import { Transactionlog } from '../transactionlog/transactionlog.model'
import { MyTransactionlogsListFilter } from '../transactionlog/my-transactionlogs-filter/my-transactionlogs-list-filter.model'

@Injectable({ providedIn: 'root' })
export class EmployerFacade {
    // Stream containing the list of employers in the state.
    @Select(EmployerSelector.employers)
    private readonly employers$!: Observable<PagedList<Employer, EmployerListFilter>>
    @Select(EmployerSelector.transactionlogs)
    private readonly transactionlogs$!: Observable<PagedList<Transactionlog, MyTransactionlogsListFilter>>
    private employerService: EmployerService = inject(EmployerService)
    private store: Store = inject(Store)

    public getEmployers(
        pageIndex: number,
        pageSize: number,
        searchCriteria: EmployerListFilter
    ): Observable<PagedList<Employer, EmployerListFilter>> {
        const dispatchLoaded = (pl: PagedList<Employer, EmployerListFilter>): Observable<unknown> =>
            this.store.dispatch(new EmployersLoaded(pl))

        return this.employers$.pipe(
            take(1),
            switchMap((employerList: PagedList<Employer, EmployerListFilter>) => {
                if (isPagedListSameQuery(employerList, pageIndex, searchCriteria)) {
                    return this.employers$
                }

                return this.employerService.getAll(pageIndex, pageSize, searchCriteria).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded),
                    switchMap(() => this.employers$)
                )
            })
        )
    }

    public getEmployerById(id: number): Observable<AsyncResult<Employer | null>> {
        return this.employerService.getById(id).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public getEmployerQrCode(id: number): Observable<AsyncResult<string | null>> {
        return this.employerService.getQrCode(id).pipe(
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public getFinancialHistoryByEmployerId(
        id: number,
        pageIndex: number,
        pageSize: number,
        searchCriteria: MyTransactionlogsListFilter
    ): Observable<PagedList<Transactionlog, MyTransactionlogsListFilter>> {
        const dispatchLoaded = (pl: PagedList<Transactionlog, MyTransactionlogsListFilter>): Observable<unknown> =>
            this.store.dispatch(new EmployerTransactionlogsLoaded(pl))

        return this.transactionlogs$.pipe(
            take(1),
            switchMap((transactionlogList: PagedList<Transactionlog, MyTransactionlogsListFilter>) => {
                if (isPagedListSameQuery(transactionlogList, pageIndex, searchCriteria)) {
                    return this.transactionlogs$
                }

                return this.employerService.getTransactionlogs(id, pageIndex, pageSize, searchCriteria).pipe(
                    expectPagedListHttpSuccess(dispatchLoaded),
                    expectPagedListHttpError(DEFAULT_HTTP_ERROR_CODES, dispatchLoaded),
                    switchMap(() => this.transactionlogs$)
                )
            })
        )
    }

    public updateEmployer(employer: Employer): Observable<AsyncResult<Employer | null>> {
        return this.employerService.update(employer).pipe(
            tap((updatedEmployer) => this.store.dispatch(new EmployerUpdated(updatedEmployer))),
            map((v) => createSuccessAsyncResult(v)),
            expectAsyncResultHttpError(DEFAULT_HTTP_ERROR_CODES, null)
        )
    }

    public clearState(): void {
        this.store.dispatch(new ClearEmployers())
    }
}
