import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { APP_CONFIG, AppConfig } from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import { CreatePaymentDto } from './create-payment'
import { Payment, PaymentDto, paymentFromDto } from './payment'

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)

    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/Payments`
    }

    public createPayment(bookingId: number): Observable<Payment> {
        const body: CreatePaymentDto = { bookingId }
        return this.http.post<PaymentDto>(this.basePath, body).pipe(map(paymentFromDto))
    }
}
