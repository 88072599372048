import { Component, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StarRating } from './star-rating'

@Component({
    selector: 'linkx-rating',
    standalone: true,
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    imports: [CommonModule]
})
export class RatingComponent implements OnInit {
    @Input() public rating!: number
    public stars: Array<string> = [
        StarRating.Empty,
        StarRating.Empty,
        StarRating.Empty,
        StarRating.Empty,
        StarRating.Empty
    ]
    public ngOnInit(): void {
        this.setStars()
    }

    public setStars(): void {
        let stars: Array<string> = [
            StarRating.Empty,
            StarRating.Empty,
            StarRating.Empty,
            StarRating.Empty,
            StarRating.Empty
        ]
        const fullStars = Math.floor(this.rating)
        stars = this.stars.fill(StarRating.Full, 0, fullStars)

        if (this.rating % 1 >= 0.5) {
            stars[fullStars] = StarRating.Half
        }
        this.stars = stars
    }
}
