import { PageEvent } from '@angular/material/paginator'
import { distinct, map, Observable } from 'rxjs'
import { watchNumberQueryParam } from '../functions/routing'
import { Constructor } from './constructor'
import { RelativeNavigationCtor } from './relative-navigation'

/**
 * Interface describing something that supports pagination.
 */
export interface CanPage {
    /** Stream emitting with the new page value. */
    page$: Observable<number>
    /** The number of items on a single page. */
    pageSize: number
    /** Handler for when the page should be changed. */
    handlePageEvent(e: PageEvent): Promise<void>
}

/** A constructable type that implements the CanPage interface. */
export type CanPageCtor = Constructor<CanPage>

/**
 * Enhances the given base class with functionality for pagination based on query parameters.
 * @param base The base class that already implements functionality for relative navigation.
 */
export const mixinPagination = <T extends RelativeNavigationCtor>(base: T): T & CanPageCtor => {
    return class extends base implements CanPage {
        public page$ = watchNumberQueryParam('page').pipe(
            map((page: number) => (page < 1 ? 1 : page)),
            distinct()
        )
        public pageSize = 20

        public async handlePageEvent(e: PageEvent): Promise<void> {
            await this.relativeNavigation([], {
                queryParams: {
                    page: e.pageIndex + 1
                },
                queryParamsHandling: 'merge'
            })
        }
    }
}
