import { DateTime } from 'luxon'
import { Employee, EmployeeDto, employeeFromDto } from '../../employee/employee.model'
import { Employer, EmployerDto, employerFromDto } from '../../employer/employer.model'
import { Qualification, QualificationDto, qualificationFromDto } from '../../qualification/qualification.model'
import { Timeslice, TimesliceDto, timesliceFromDto } from '../../timeslice/timeslice.model'
import { BookingStatus } from '../booking-status.enum'

export interface Booking {
    description: string
    employee: Employee
    employeeApprovalDate: DateTime | null
    employer: Employer
    hourlyWage: number
    vatPct: number
    feePct: number
    id: number
    locationRemarks: string
    numberOfHours: number
    qualification: Qualification
    remainingNumberOfMinutes: number
    remainingBalance: number
    remarks: string
    startDate: DateTime
    status: BookingStatus
    timeSlices: Array<Timeslice>
}

export interface BookingDto {
    description: string
    employeeApprovalDate: string | null
    employee: EmployeeDto
    employer: EmployerDto
    hourlyWage: number
    vatPct: number
    feePct: number
    id: number
    locationRemarks: string
    numberOfMinutes: number
    qualification: QualificationDto
    remainingNumberOfMinutes: number
    remainingBalance: number
    remarks: string
    startDate: string
    status: string
    timeSlices: Array<TimesliceDto>
}

export const bookingFromDto = (bookingDto: BookingDto): Booking => {
    const timeSlices: Array<Timeslice> = bookingDto.timeSlices.map(timesliceFromDto)
    return {
        ...bookingDto,
        employee: employeeFromDto(bookingDto.employee),
        employeeApprovalDate:
            bookingDto.employeeApprovalDate !== null && bookingDto.employeeApprovalDate !== undefined
                ? DateTime.fromISO(bookingDto.employeeApprovalDate)
                : null,
        employer: employerFromDto(bookingDto.employer),
        numberOfHours: bookingDto.numberOfMinutes / 60,
        remainingNumberOfMinutes:
            bookingDto.numberOfMinutes - timeSlices.reduce((sum, ts) => sum + (ts.duration ?? 0), 0),
        qualification: qualificationFromDto(bookingDto.qualification),
        startDate: DateTime.fromISO(bookingDto.startDate),
        status: bookingDto.status as BookingStatus,
        timeSlices
    }
}

export const applyFeeAndVat = (value: number, feePct: number, vatPct: number): number => {
    return value * (1 + vatPct / 100) * (1 + feePct / 100)
}

export const bookingHasBeenConfirmed = (booking: Booking): boolean => {
    return (
        [
            BookingStatus.PAID,
            BookingStatus.CONFIRMED_BY_EMPLOYEE,
            BookingStatus.INTERRUPTED,
            BookingStatus.CANCELED,
            BookingStatus.CLOSED
        ].indexOf(booking.status) > -1
    )
}
