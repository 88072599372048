import { BookingStatus } from '../booking-status.enum'

export interface BookingListFilter {
    [BookingStatus.NONE]?: boolean
    [BookingStatus.INITIATED]?: boolean
    [BookingStatus.CONFIRMED_BY_EMPLOYEE]?: boolean
    [BookingStatus.PAYMENT_INITIATED]?: boolean
    [BookingStatus.PAID]?: boolean
    [BookingStatus.CLOSED]?: boolean
    [BookingStatus.INTERRUPTED]?: boolean
    [BookingStatus.CANCELED]?: boolean
}
export interface BookingListFilterDto {
    statuses: Array<string>
}
export const bookingListFilterToDto = (bookingListFilter: BookingListFilter): BookingListFilterDto => {
    const selectedStatuses = Object.keys(bookingListFilter)
        .filter((key) => bookingListFilter[key as BookingStatus])
        .map((status) => status.toString())
    return { statuses: selectedStatuses }
}
