import { LinkxUserInfo } from '../linkx-user-info'

export class UserAuthenticated {
    public static readonly type = '[Auth] User'
    public constructor(public userInfo: LinkxUserInfo | null) {}
}

export class UserSignedOut {
    public static readonly type = '[Auth] User signed out'
}
