import { CommonModule } from '@angular/common'
import { Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'

@Component({
    selector: 'linkx-autocomplete-input',
    templateUrl: './autocomplete-input.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteInputComponent),
            multi: true
        }
    ]
})
export class AutocompleteInputComponent implements ControlValueAccessor, OnInit {
    @ContentChild('optionTemplate', { read: TemplateRef }) public optionTemplate!: TemplateRef<unknown>
    @Input() public options: Array<unknown> | null = []
    @Input() public label?: string
    /* eslint-disable @typescript-eslint/no-explicit-any */
    @Input() public displayWith: ((value: any) => string) | null = null
    /* eslint-enable @typescript-eslint/no-explicit-any */
    @Output() public filter: EventEmitter<string> = new EventEmitter()
    @Output() public optionSelected: EventEmitter<unknown> = new EventEmitter<unknown>()

    public formControl: FormControl = new FormControl()

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onChange: (value: unknown) => void = () => {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched: (value: unknown) => void = () => {}

    public ngOnInit(): void {
        this.formControl.valueChanges.subscribe((value) => {
            this.onChange?.(typeof value === 'string' ? null : value)
        })
    }

    public writeValue(value: unknown): void {
        this.formControl.setValue(value, { emitEvent: false })
    }

    public registerOnChange(fn: (value: unknown) => void): void {
        this.onChange = fn
    }

    public registerOnTouched(fn: (value: unknown) => void): void {
        this.onTouched = fn
    }

    public inputChanged(): void {
        this.filter.emit(this.formControl.value)
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled && !this.formControl.disabled) {
            this.formControl.disable()
        } else if (!isDisabled && !this.formControl.enabled) {
            this.formControl.enable()
        }
    }
}
