import { CommonModule } from '@angular/common'
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { PrimaryButtonComponent } from '../primary-button/primary-button.component'
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component'

@Component({
    selector: 'linkx-filter',
    templateUrl: './filter.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        SecondaryButtonComponent,
        PrimaryButtonComponent,
        MatIconModule
    ]
})
export class FilterComponent {
    @ViewChild(MatExpansionPanel) public expansionPanel!: MatExpansionPanel
    @ContentChild('searchform', { read: TemplateRef }) public searchForm!: TemplateRef<unknown>
    @Input() public submitDisabled = false
    @Input() public label?: string
    @Input() public initiallyExpanded = true
    @Output() public searchClick = new EventEmitter<void>()
    @Output() public resetClick = new EventEmitter<void>()

    public onSearch(): void {
        this.expansionPanel.close()
        this.searchClick.emit()
    }

    public onReset(): void {
        this.resetClick.emit()
    }
}
