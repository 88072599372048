<ng-container *ngIf="currentUser$ | async as currentUser">
    <div class="page">
        <h1 class="page-title">{{ 'auth.welcome' | translate: { name: currentUser.givenName } }}</h1>
        <div *ngIf="!timer">
            <span>{{ 'auth.welcome-message' | translate }}</span>
        </div>
        <div *ngIf="timer">
            <span>{{ 'auth.redirect-message' | translate }}</span>
        </div>
    </div>
</ng-container>
