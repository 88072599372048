export interface EmployerListFilter {
    companyName: string
    companyCity: string
    email: string
    vatNumber: string
    contactPerson: string
}

export interface EmployerListFilterDto {
    companyName: string
    companyCity: string
    email: string
    vatNumber: string
    contactPerson: string
}

export const employerListFilterToDto = (employerListFilter: EmployerListFilter): EmployerListFilterDto => {
    return {
        ...employerListFilter
    }
}
