export interface Address {
    street: string
    zipCode: string
    city: string
    country: string
    houseNumber: string
}

export interface AddressDto {
    street: string
    zipCode: string
    city: string
    country: string
    houseNumber: string
}

export const addressToDto = (address: Address): AddressDto => {
    return { ...address }
}

export const addressFromDto = (addressDto: AddressDto): Address => {
    return { ...addressDto }
}
