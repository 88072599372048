export interface Language {
    id: number | null
    code: string
    rating: number | null
}

export interface LanguageDto {
    id: number | null
    code: string
    rating: number | null
}

export const languageToDto = (language: Language): LanguageDto => {
    return {
        ...language,
        code: language.code.toUpperCase()
    }
}

export const languageFromDto = (languageDto: LanguageDto): Language => {
    return {
        ...languageDto,
        code: languageDto.code.toLowerCase()
    }
}
