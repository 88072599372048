<mat-drawer-container class="app-wireframe-container">
    <mat-drawer
        [mode]="isXSmallDevice ? 'over' : 'side'"
        [ngClass]="isXSmallDevice ? 'drawer-full-width' : 'drawer'"
        [opened]="!isSmallDevice && !isXSmallDevice"
        class="drawer-color">
        <linkx-left-sidenav
            (closeSidebar)="closeSidebar()"
            [navigationItems]="navigationItems"
            [showMenuCloseButton]="isXSmallDevice">
        </linkx-left-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <linkx-toolbar
            (toggleSidebar)="toggleSidebar()"
            [isSidenavOpen]="isSidenavOpen"
            [showMenuToggle]="isSmallDevice || (!isSidenavOpen && !isLargerDevice)">
        </linkx-toolbar>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
