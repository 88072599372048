import { PagedList } from '@linkx/shared-utils'
import { Selector } from '@ngxs/store'
import { BookingItem } from '../booking/booking-item.model'
import { BookingListFilter } from '../booking/booking-list-filter.model'
import { EmployeeBookingFilter } from '../booking/employee-booking-filter.model'
import { EmployeeBooking } from '../booking/employee-booking.model'
import { EmployerBookingFilter } from '../booking/employer-booking-filter.model'
import { EmployerBooking } from '../booking/employer-booking.model'
import { MyBookingsListFilter } from '../mybooking-filter/my-bookings-list-filter.model'
import { MyBookingsListItem } from '../mybooking/my-bookings-list-item.model'
import { BookingsState, BookingStateModel } from './booking.state'
export class BookingSelector {
    @Selector([BookingsState])
    public static myBookings(state: BookingStateModel): PagedList<MyBookingsListItem, MyBookingsListFilter> {
        return state.myBookings
    }

    @Selector([BookingsState])
    public static bookings(state: BookingStateModel): PagedList<BookingItem, BookingListFilter> {
        return state.bookings
    }

    @Selector([BookingsState])
    public static employerBookings(state: BookingStateModel): PagedList<EmployerBooking, EmployerBookingFilter> {
        return state.employerBookings
    }

    @Selector([BookingsState])
    public static employeeBookings(state: BookingStateModel): PagedList<EmployeeBooking, EmployeeBookingFilter> {
        return state.employeeBookings
    }
}
