import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { LinkxUserInfo } from '../linkx-user-info'
import { UserAuthenticated, UserSignedOut } from './auth.action'

export interface AuthStateModel {
    authenticatedUser: LinkxUserInfo | null
}

export const AUTH_STATE_DEFAULTS: AuthStateModel = {
    authenticatedUser: null
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: AUTH_STATE_DEFAULTS
})
@Injectable()
export class AuthState {
    @Action(UserAuthenticated)
    public userAuthenticated(ctx: StateContext<AuthStateModel>, action: UserAuthenticated): void {
        ctx.patchState({ authenticatedUser: action.userInfo })
    }

    @Action(UserSignedOut)
    public userSignedOUt(ctx: StateContext<AuthStateModel>): void {
        ctx.patchState({ authenticatedUser: null })
    }
}
