import { Certificate, CertificateDto, certificateFromDto, certificateToDto } from '../../certificate/certificate.model'
import { Language, LanguageDto, languageFromDto, languageToDto } from '../../language/language.model'

export interface EmployeeEducationDto {
    languages: Array<LanguageDto>
    certificates: Array<CertificateDto>
}

export interface EmployeeEducation {
    languages: Array<Language>
    certificates: Array<Certificate>
}

export const employeeEducationFromDto = (dto: EmployeeEducationDto): EmployeeEducation => {
    return {
        languages: dto.languages.map(languageFromDto),
        certificates: dto.certificates.map(certificateFromDto)
    }
}

export const employeeEducationToDto = (model: EmployeeEducation): EmployeeEducationDto => {
    return {
        languages: model.languages.map(languageToDto),
        certificates: model.certificates.map(certificateToDto)
    }
}
