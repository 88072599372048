<mat-expansion-panel [expanded]="initiallyExpanded">
    <mat-expansion-panel-header>
        <mat-panel-title> <mat-icon>filter_alt</mat-icon> {{ label }} </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content #searchForm> </ng-content>
    <div class="button-bar">
        <linkx-secondary-button (click)="onReset()" label="buttons.reset" fontIcon="fa-refresh" color="accent">
        </linkx-secondary-button>
        <linkx-primary-button
            fontIcon="fa-magnifying-glass"
            (click)="onSearch()"
            [disabled]="submitDisabled"
            label="buttons.search">
        </linkx-primary-button>
    </div>
</mat-expansion-panel>
