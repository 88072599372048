import { PagedList } from '@linkx/shared-utils'
import { EmployeeListFilter } from '../employee-list/employee-list-filter.model'
import { EmployeeSearchFilter } from '../employee-search/employee-search-filter.model'
import { EmployeeSearch } from '../employee-search/employee-search.model'
import { Employee } from '../employee.model'

export class BackofficeEmployeesLoaded {
    public static readonly type = '[EmployeesSearch] Backoffice employees loaded'
    public constructor(public employees: PagedList<EmployeeSearch, EmployeeListFilter>) {}
}
export class EmployeeSearchLoaded {
    public static readonly type = '[EmployeesSearch] EmployeesSearch loaded'
    public constructor(public employeesSearch: PagedList<EmployeeSearch, EmployeeSearchFilter>) {}
}
export class EmployeeUpdated {
    public static readonly type = '[Employees] Employees updated'
    public constructor(public employee: Employee) {}
}

export class EmployeeSearchInserted {
    public static readonly type = '[Employees] Employees inserted'
    public constructor(public employee: Employee) {}
}

export class ClearEmployees {
    public static readonly type = '[Employees] Employees cleared'
}

export class ClearEmployeeSearch {
    public static readonly type = '[EmployeeSearchItems] EmployeeSearchItems cleared'
}
