export enum TransactionlogStatus {
    NONE = 'NONE',
    MONEY_DEPOSIT = 'MONEY_DEPOSIT',
    BOOKING_FINANCED = 'BOOKING_FINANCED',
    SERVICES_COMPLETED = 'SERVICES_COMPLETED',
    SERVICES_CORRECTED = 'SERVICES_CORRECTED',
    BOOKING_INTERRUPTED = 'BOOKING_INTERRUPTED',
    UNDO_SERVICES_COMPLETED = 'UNDO_SERVICES_COMPLETED',
    BOOKING_CANCELED = 'BOOKING_CANCELED',
    MONEY_WITHDRAWAL = 'MONEY_WITHDRAWAL',
    MONEY_CORRECTION = 'MONEY_CORRECTION'
}
