import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { Severity } from './severity'

@Component({
    selector: 'linkx-message-bar',
    templateUrl: './message-bar.component.html',
    styleUrls: ['./message-bar.component.scss'],
    imports: [CommonModule, MatCardModule],
    standalone: true
})
export class MessageBarComponent {
    @Input() public severity!: Severity
    @Input() public message!: string
}
