import { BookingStatus } from '../booking-status.enum'
import { DateTime } from 'luxon'

export interface MyBookingsListFilter {
    [BookingStatus.CANCELED]: boolean
    [BookingStatus.CLOSED]: boolean
    [BookingStatus.CONFIRMED_BY_EMPLOYEE]: boolean
    [BookingStatus.INITIATED]: boolean
    [BookingStatus.INTERRUPTED]: boolean
    [BookingStatus.PAID]: boolean
    [BookingStatus.PAYMENT_INITIATED]: boolean
    firstName: string
    lastName: string
    companyCity: string
    companyName: string
    start: DateTime | null
    end: DateTime | null
}

export interface MyBookingsListFilterDto extends Record<string, unknown> {
    bookingStatuses: Array<string>
    firstName: string
    lastName: string
    companyCity: string
    companyName: string
    start: DateTime | null
    end: DateTime | null
}

export const myBookingsListFilterToDto = (model: MyBookingsListFilter): MyBookingsListFilterDto => {
    const bookingStatuses = Object.keys(model)
        .filter((key) => BookingStatus[key as keyof typeof BookingStatus] && model[key as keyof MyBookingsListFilter])
        .map((key) => BookingStatus[key as keyof typeof BookingStatus])

    return {
        firstName: model.firstName,
        lastName: model.lastName,
        companyCity: model.companyCity,
        companyName: model.companyName,
        start: model.start?.toUTC() ?? null,
        end: model.end?.toUTC() ?? null,
        bookingStatuses
    }
}
