import { inject, OnDestroy, Pipe, PipeTransform } from '@angular/core'
import { LanguageFacade } from '@linkx/shared-data-access'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'

@Pipe({
    name: 'translateLanguage',
    standalone: true
})
export class TranslateLanguagePipe implements PipeTransform, OnDestroy {
    private languageFacade: LanguageFacade = inject(LanguageFacade)
    private translateService: TranslateService = inject(TranslateService)
    private translations!: Record<string, string>
    private languageSubscription: Subscription = this.translateService.onLangChange.asObservable().subscribe(() => {
        this.translations = this.languageFacade.getLanguageTranslations(this.translateService.currentLang)
    })

    public ngOnDestroy(): void {
        this.languageSubscription.unsubscribe()
    }

    public transform(value: string): string | null {
        this.translations ??= this.languageFacade.getLanguageTranslations(this.translateService.currentLang)
        return this.translations?.[value] ?? value
    }
}
