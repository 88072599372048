import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { APP_CONFIG, AppConfig } from '@linkx/shared-utils'
import { forkJoin, map, Observable, tap } from 'rxjs'
import { Qualification } from '../qualification/qualification.model'

export interface MetadataState {
    certificates: Array<string>
    countries: Array<string>
    languages: Array<string>
    qualifications: Array<Qualification>
    countriesTranslations: Record<string, Record<string, string>>
    languagesTranslations: Record<string, Record<string, string>>
}

type MetadataStateTranslationKeys = keyof Pick<
    MetadataState,
    {
        [K in keyof MetadataState]: MetadataState[K] extends Record<string, Record<string, string>> ? K : never
    }[keyof MetadataState]
>

@Injectable()
export class MetadataService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)
    private supportedLanguages: Array<string> = ['nl', 'en']

    public state: MetadataState = {
        certificates: [],
        countries: [],
        languages: [],
        qualifications: [],
        countriesTranslations: {},
        languagesTranslations: {}
    }

    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/metadata`
    }

    public loadData(): Observable<void> {
        return forkJoin([
            this.loadMetadataFromApi('certificates', 'certificates'),
            this.loadMetadataFromApi('countries', 'countries'),
            this.loadMetadataFromApi('languages', 'languages'),
            this.loadMetadataFromApi('qualifications', 'qualifications'),
            this.loadMetadataTranslations('cldr/countries', 'countriesTranslations'),
            this.loadMetadataTranslations('cldr/languages', 'languagesTranslations')
        ]).pipe(map(() => void 0))
    }

    private loadMetadataFromApi<T extends keyof MetadataState>(
        endpointSubPath: string,
        targetStateKey: T
    ): Observable<MetadataState[T]> {
        return this.http
            .get<MetadataState[T]>(`${this.basePath}/${endpointSubPath}`)
            .pipe(tap((results) => (this.state[targetStateKey] = results)))
    }

    private loadMetadataTranslations(
        sharedAssetsSubPath: string,
        targetStateKey: MetadataStateTranslationKeys
    ): Observable<Array<Record<string, string>>> {
        return forkJoin([
            ...this.supportedLanguages.map((language) =>
                this.http.get<Record<string, string>>(`./shared-assets/${sharedAssetsSubPath}/${language}.json`).pipe(
                    tap((translations) => {
                        const targetState: Record<string, Record<string, string>> = this.state[targetStateKey]
                        targetState[language] = translations
                    })
                )
            )
        ])
    }
}
