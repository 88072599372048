import { Company, CompanyDto, companyFromDto, companyToDto } from '../company/company.model'

export interface Employer {
    id: number | null
    company: Company
    contactPerson: string
    email: string
    phoneNumber: string
    balance: number | null
}

export interface EmployerDto {
    id: number | null
    company: CompanyDto
    contactPerson: string
    email: string
    phoneNumber: string
    balance: number | null
}

//convert from employer to employerDto
export const employerToDto = (employer: Employer): EmployerDto => {
    return {
        ...employer,
        company: companyToDto(employer.company)
    }
}

//convert from employerDto to employer
export const employerFromDto = (employerDto: EmployerDto): Employer => {
    return {
        ...employerDto,
        company: companyFromDto(employerDto.company)
    }
}
