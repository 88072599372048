import { inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { MetadataService } from '../metadata/metadata.service'
import { Qualification } from './qualification.model'

@Injectable({ providedIn: 'root' })
export class QualificationFacade {
    private metadata: MetadataService = inject(MetadataService)
    public getQualifications(): Observable<Array<Qualification>> {
        return of(this.metadata.state.qualifications)
    }
}
