import { Component, inject, Input, OnChanges, ViewEncapsulation } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { NgIf } from '@angular/common'

@Component({
    selector: 'linkx-progress-bar',
    standalone: true,
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    imports: [MatTooltipModule, TranslateModule, NgIf],
    encapsulation: ViewEncapsulation.Emulated
})
export class ProgressBarComponent implements OnChanges {
    @Input() public numberOfMinutes!: number
    @Input() public remainingNumberOfMinutes!: number
    @Input() public includeNumbers = true

    public width = 100
    public backgroundColor = '#ddd'

    public translate: TranslateService = inject(TranslateService)
    public ngOnChanges(): void {
        if (this.numberOfMinutes == 0) {
            this.width = 0
        }
        this.width = Math.round(((this.numberOfMinutes - this.remainingNumberOfMinutes) / this.numberOfMinutes) * 100)
        this.updateColor()
    }
    private updateColor(): void {
        //TODO: Change these ugly colors and use hex values!
        if (this.width < 50) {
            this.backgroundColor = 'green'
        } else if (this.width < 75) {
            this.backgroundColor = 'orange'
        } else {
            this.backgroundColor = 'red'
        }
    }
}
