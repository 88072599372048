import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { TransactionlogStatus } from '@linkx/shared-data-access'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'

@Component({
    selector: 'linkx-transactionlog-status',
    templateUrl: './transactionlog-status.component.html',
    styleUrls: ['./transactionlog-status.component.scss'],
    standalone: true,
    imports: [TranslateModule, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionlogStatusComponent {
    @Input() public status!: TransactionlogStatus
    @Input() public showStatusText!: boolean | null

    public get translationText(): string {
        return 'transactionlog-status.enum.' + this.status
    }
}
