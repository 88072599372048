import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

export const requireOneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormGroup) {
        const values = Object.values(control.value)
        const hasValue = values.some((value) => !!value)
        return hasValue ? null : { requireOne: true }
    } else {
        return null
    }
}
