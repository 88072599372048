<mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input type="text" [formControl]="formControl" (input)="inputChanged()" matInput [matAutocomplete]="auto" />
    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayWith"
        (optionSelected)="optionSelected.emit($event.option.value)">
        <mat-option *ngFor="let option of options" [value]="option">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { option: option }"></ng-container>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
