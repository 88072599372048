import { CommonModule } from '@angular/common'
import { LOCALE_ID, NgModule } from '@angular/core'
import { LuxonDateAdapter } from '@angular/material-luxon-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core'

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'nl-BE'
        },
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD'
                },
                display: {
                    dateInput: 'DD',
                    monthYearLabel: 'MMM yyyy',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM yyyy'
                }
            } as MatDateFormats
        }
    ]
})
export class SharedDateUtilsModule {}
