import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import {
    APP_CONFIG,
    AppConfig,
    extractPrimitiveNonNullProperties,
    PagedEntities,
    pagedEntitiesFromDto
} from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import { BookingItem, BookingItemDto, bookingItemFromDto } from '../booking/booking-item.model'
import { BookingListFilter, bookingListFilterToDto } from '../booking/booking-list-filter.model'
import { Booking, BookingDto, bookingFromDto } from '../booking/booking.model'
import { EmployeeBookingFilter, employeeBookingFilterToDto } from '../booking/employee-booking-filter.model'
import { EmployeeBooking, EmployeeBookingDto, employeeBookingFromDto } from '../booking/employee-booking.model'
import { EmployerBookingFilter, employerBookingFilterToDto } from '../booking/employer-booking-filter.model'
import { EmployerBooking, EmployerBookingDto, employerBookingFromDto } from '../booking/employer-booking.model'
import { UpdateBookingAsAdmin, updateBookingAsAdminToDto } from '../booking/update-booking-as-admin.model'

@Injectable({
    providedIn: 'root'
})
export class BackofficeBookingService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)

    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/backoffice/bookings`
    }

    public getById(bookingId: number): Observable<Booking> {
        return this.http.get<BookingDto>(`${this.basePath}/${bookingId}`).pipe(map(bookingFromDto))
    }

    public getAll(
        page: number,
        pageSize: number,
        searchCriteria: BookingListFilter
    ): Observable<PagedEntities<BookingItem>> {
        return this.http
            .get<PagedEntities<BookingItemDto>>(this.basePath, {
                params: {
                    ...bookingListFilterToDto(searchCriteria),
                    page: page,
                    pageSize: pageSize
                }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, bookingItemFromDto)))
    }

    public getAllByEmployerId(
        employerId: number,
        page: number,
        pageSize: number,
        filter: EmployerBookingFilter
    ): Observable<PagedEntities<EmployerBooking>> {
        return this.http
            .get<PagedEntities<EmployerBookingDto>>(`${this.basePath}/Employer/${employerId}`, {
                params: {
                    ...extractPrimitiveNonNullProperties(employerBookingFilterToDto(filter)),
                    page,
                    pageSize
                }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, employerBookingFromDto)))
    }

    public getAllByEmployeeId(
        employeeId: number,
        page: number,
        pageSize: number,
        filter: EmployeeBookingFilter
    ): Observable<PagedEntities<EmployeeBooking>> {
        return this.http
            .get<PagedEntities<EmployeeBookingDto>>(`${this.basePath}/Employee/${employeeId}`, {
                params: {
                    ...extractPrimitiveNonNullProperties(employeeBookingFilterToDto(filter)),
                    page,
                    pageSize
                }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, employeeBookingFromDto)))
    }

    public update(booking: UpdateBookingAsAdmin): Observable<Booking> {
        return this.http
            .post<BookingDto>(`${this.basePath}/${booking.id}`, updateBookingAsAdminToDto(booking))
            .pipe(map((dto: BookingDto) => bookingFromDto(dto)))
    }

    public approve(bookingId: number): Observable<Booking> {
        return this.http
            .post<BookingDto>(`${this.basePath}/${bookingId}/Approve`, { approvedByEmployee: true })
            .pipe(map(bookingFromDto))
    }

    public changeEmployee(bookingId: number, employeeId: number): Observable<Booking> {
        return this.http
            .post<BookingDto>(`${this.basePath}/${bookingId}/UpdateEmployee`, { employeeId })
            .pipe(map(bookingFromDto))
    }
}
