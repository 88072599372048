import { Selector } from '@ngxs/store'
import { EmployersState, EmployerStateModel } from './employer.state'
import { Employer } from '../employer.model'
import { PagedList } from '@linkx/shared-utils'
import { EmployerListFilter } from '../employer-list-filter/employer-list-filter.model'
import { Transactionlog } from '../../transactionlog/transactionlog.model'
import { MyTransactionlogsListFilter } from '../../transactionlog/my-transactionlogs-filter/my-transactionlogs-list-filter.model'
export class EmployerSelector {
    @Selector([EmployersState])
    public static employers(state: EmployerStateModel): PagedList<Employer, EmployerListFilter> {
        return state.employers
    }

    @Selector([EmployersState])
    public static transactionlogs(state: EmployerStateModel): PagedList<Transactionlog, MyTransactionlogsListFilter> {
        return state.transactionlogs
    }
}
