import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { EmployeeSearchFilter, employeeSearchFilterToDto } from '../employee-search/employee-search-filter.model'
import { APP_CONFIG, AppConfig, PagedEntities, pagedEntitiesFromDto } from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import {
    EmployeeSearchDetail,
    EmployeeSearchDetailDto,
    employeeSearchDetailFromDto
} from '../employee-search/employee-search-detail.model'
import { EmployeeSearch, EmployeeSearchDto, employeeSearchFromDto } from '../employee-search/employee-search.model'

@Injectable({
    providedIn: 'root'
})
export class FrontOfficeEmployeeService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)

    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/frontoffice/employees`
    }

    public getSearchDetailById(id: number): Observable<EmployeeSearchDetail> {
        return this.http
            .get<EmployeeSearchDetailDto>(`${this.basePath}/${id}/SearchDetails`)
            .pipe(map(employeeSearchDetailFromDto))
    }

    public getAllByCriteria(
        searchFilter: EmployeeSearchFilter,
        page: number,
        pageSize: number
    ): Observable<PagedEntities<EmployeeSearch>> {
        return this.http
            .get<PagedEntities<EmployeeSearchDto>>(this.basePath + `/search`, {
                params: { ...employeeSearchFilterToDto(searchFilter), page, pageSize }
            })
            .pipe(map((result) => pagedEntitiesFromDto(result, employeeSearchFromDto)))
    }
}
