import { inject } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmationDialogComponent, IConfirmationDialogData } from '@linkx/shared-components-ui'
import { CanDeactivatePage } from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'

export const canDeactivateGuard =
    <T extends CanDeactivatePage>() =>
    (component: T): boolean | Observable<boolean> => {
        if (component.canDeactivate()) {
            return true
        }

        return inject(MatDialog)
            .open<ConfirmationDialogComponent, IConfirmationDialogData>(ConfirmationDialogComponent, {
                data: {
                    titleKey: 'can-deactivate-confirmation.title',
                    bodyKey: 'can-deactivate-confirmation.message',
                    confirmationKey: 'can-deactivate-confirmation.confirm',
                    cancelKey: 'can-deactivate-confirmation.cancel'
                } as IConfirmationDialogData
            })
            .afterClosed()
            .pipe(map((result) => !!result))
    }
