import { HttpClient, HttpParams } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import {
    APP_CONFIG,
    AppConfig,
    extractPrimitiveNonNullProperties,
    PagedEntities,
    pagedEntitiesFromDto
} from '@linkx/shared-utils'
import { map, Observable } from 'rxjs'
import {
    EmployeeEducation,
    EmployeeEducationDto,
    employeeEducationFromDto,
    employeeEducationToDto
} from '../employee-education/employee-education'
import {
    EmployeeListFilter,
    EmployeeListFilterDto,
    employeeListFilterToDto
} from '../employee-list/employee-list-filter.model'
import { EmployeeSearch, EmployeeSearchDto, employeeSearchFromDto } from '../employee-search/employee-search.model'
import {
    EmployeeUpdateWorkExperience,
    EmployeeUpdateWorkExperienceDto,
    employeeUpdateWorkexperienceFromDto,
    employeeUpdateWorkexperienceToDto
} from '../employee-workexperience/employee-workexperience.model'
import { Employee, EmployeeDto, employeeFromDto, employeeToDto } from '../employee.model'

@Injectable({
    providedIn: 'root'
})
export class BackOfficeEmployeeService {
    private http: HttpClient = inject(HttpClient)
    private appConfig: AppConfig = inject(APP_CONFIG)

    private get basePath(): string {
        return `${this.appConfig.api.url}/${this.appConfig.api.version}/backoffice/employees`
    }

    public getById(id: number): Observable<Employee> {
        return this.http.get<EmployeeDto>(`${this.basePath}/${id}`).pipe(map(employeeFromDto))
    }

    public getAll(
        page: number,
        pageSize: number,
        listFilter: EmployeeListFilter
    ): Observable<PagedEntities<EmployeeSearch>> {
        const filterDto: EmployeeListFilterDto = employeeListFilterToDto(listFilter)
        const params = new HttpParams({
            fromObject: {
                ...extractPrimitiveNonNullProperties(filterDto),
                page: page,
                pageSize: pageSize
            }
        })

        return this.http
            .get<PagedEntities<EmployeeSearchDto>>(`${this.basePath}`, { params })
            .pipe(map((result) => pagedEntitiesFromDto(result, employeeSearchFromDto)))
    }

    public insert(employee: Employee): Observable<Employee> {
        return this.http
            .put<EmployeeDto>(this.basePath, employeeToDto(employee))
            .pipe(map((dto: EmployeeDto) => employeeFromDto(dto)))
    }

    public update(employee: Employee): Observable<Employee> {
        return this.http
            .post<EmployeeDto>(`${this.basePath}/${employee.id}`, employeeToDto(employee))
            .pipe(map((dto: EmployeeDto) => employeeFromDto(dto)))
    }

    public updateEducation(employeeId: number, education: EmployeeEducation): Observable<EmployeeEducation> {
        return this.http
            .put<EmployeeEducationDto>(`${this.basePath}/${employeeId}/Education`, employeeEducationToDto(education))
            .pipe(map((dto: EmployeeEducationDto) => employeeEducationFromDto(dto)))
    }

    public updateWorkExperience(
        employeeId: number,
        workExperiences: EmployeeUpdateWorkExperience
    ): Observable<EmployeeUpdateWorkExperience> {
        return this.http
            .put<EmployeeUpdateWorkExperienceDto>(
                `${this.basePath}/${employeeId}/WorkExperience`,
                employeeUpdateWorkexperienceToDto(workExperiences)
            )
            .pipe(map(employeeUpdateWorkexperienceFromDto))
    }
}
