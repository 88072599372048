import { DateTime } from 'luxon'
import { Qualification, QualificationDto, qualificationFromDto } from '../../qualification/qualification.model'
import { BookingStatus } from '../booking-status.enum'

export interface MyBookingsListItem {
    id: number
    createdAt: DateTime
    description: string
    employeeName: string
    employeeId: number
    numberOfMinutes: number
    qualification: Qualification
    remainingNumberOfMinutes: number
    completionPercentage: number
    startDate: DateTime
    status: BookingStatus
}

export interface MyBookingsListItemDto {
    id: number
    createdAt: string
    description: string
    employeeName: string
    employeeId: number
    numberOfMinutes: number
    qualification: QualificationDto
    remainingNumberOfMinutes: number
    startDate: string
    status: string
}

export const myBookingsListItemFromDto = (dto: MyBookingsListItemDto): MyBookingsListItem => {
    return {
        ...dto,
        description: dto.description ?? '',
        startDate: DateTime.fromISO(dto.startDate),
        createdAt: DateTime.fromISO(dto.createdAt),
        completionPercentage: ((dto.numberOfMinutes - dto.remainingNumberOfMinutes) / dto.numberOfMinutes) * 100,
        status: dto.status as BookingStatus,
        qualification: qualificationFromDto(dto.qualification)
    }
}
