import { DateTime } from 'luxon'
import { TransactionlogStatus } from '../transactionlog-status.enum'

export interface MyTransactionlogsListFilter {
    [TransactionlogStatus.BOOKING_CANCELED]: boolean
    [TransactionlogStatus.BOOKING_FINANCED]: boolean
    [TransactionlogStatus.BOOKING_INTERRUPTED]: boolean
    [TransactionlogStatus.MONEY_DEPOSIT]: boolean
    [TransactionlogStatus.MONEY_CORRECTION]: boolean
    [TransactionlogStatus.MONEY_WITHDRAWAL]: boolean
    [TransactionlogStatus.SERVICES_COMPLETED]: boolean
    [TransactionlogStatus.SERVICES_CORRECTED]: boolean
    [TransactionlogStatus.UNDO_SERVICES_COMPLETED]: boolean
    start: DateTime | null
    end: DateTime | null
}

export interface MyTransactionlogsListFilterDto extends Record<string, unknown> {
    transactionlogStatuses: Array<string>
    start: DateTime | null
    end: DateTime | null
}

export const MyTransactionlogsListFilterToDto = (
    model: MyTransactionlogsListFilter
): MyTransactionlogsListFilterDto => {
    const transactionlogStatuses = Object.keys(model)
        .filter(
            (key) =>
                TransactionlogStatus[key as keyof typeof TransactionlogStatus] &&
                model[key as keyof MyTransactionlogsListFilter]
        )
        .map((key) => TransactionlogStatus[key as keyof typeof TransactionlogStatus])

    return {
        start: model.start?.toUTC() ?? null,
        end: model.end?.toUTC() ?? null,
        transactionlogStatuses
    }
}
